import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    DataGridPro,
    DataGridProProps,
    GridFooterContainerProps,
    GridSortModel,
} from '@mui/x-data-grid-pro';
import { Box, Checkbox, InputAdornment, Stack, Typography } from '@mui/material';
import { atom, useAtom } from 'jotai/index';

import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { debounce, uniqBy } from 'lodash';
import {
    AutoGrid,
    Button,
    Chip,
    DialogV2,
    SelectField,
    TextAreaField,
    TextField,
} from 'c-components';

import { useCommonTranslation } from 'c-translation';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { ListSearchOptions } from '@uniled/api-sdk';
import { generateSearchColumnsData } from 'c-pagination';
import to from 'await-to-js';
import { NetworkRequestState } from '@uniled/data-layer';
import ScreenFooter from 'c-main/Components/Schedule/Components/LineItems/ScreenFooter';
import { Search } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';

interface ScreenTableProps {
    campaignId: number;
    handleDetach: (id: number | string, type: string, schedules: any[]) => void;
    setExpandedScheduleId: (id: number) => void;
    lineItemId: number | string;
    scheduleLineItems: any[];
    reloadStateMap: Record<string, boolean>;
    setReloadStateMap: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

interface Item {
    id: number | string;
    name: string;
    owner: string;
    schedules: string[];
    type: string;
    frameId?: string;
}

export const atom_screenData = atom<{ [key: string]: any[] }>({});
export const atom_currentPage = atom<{ [key: string]: number }>({});
export const atom_perPage = atom<{ [key: string]: number }>({});
export const atom_totalPages = atom<{ [key: string]: number }>({});
export const atom_totalRows = atom<{ [key: string]: number }>({});
export const atom_orderBy = atom<{ [key: string]: string }>({});
export const atom_direction = atom<{ [key: string]: 'asc' | 'desc' }>({});
export const atom_frameIdFilter = atom<{ [key: string]: string }>({});

export const atom_scheduleFilters = atom<{ [key: string]: string[] }>({});

const atom_search = atom<{ [key: string]: string }>({});
const atom_filter = atom<{ [key: string]: string }>({});
const atom_campaignId = atom<number>(null as number);

const textSearchColumnsLineItemScreens = ['any'];

const ScreenTable: React.FC<ScreenTableProps> = ({
    campaignId,
    handleDetach,
    setExpandedScheduleId,
    lineItemId,
    scheduleLineItems,
    reloadStateMap,
    setReloadStateMap,
}) => {
    const t = useCommonTranslation();
    const [atomCampaignId, setAtomCampaignId] = useAtom(atom_campaignId);
    const [screenDataMap, setScreenDataMap] = useAtom(atom_screenData);
    const [currentPageMap, setCurrentPageMap] = useAtom(atom_currentPage);
    const [perPageMap, setPerPageMap] = useAtom(atom_perPage);
    const [totalPagesMap, setTotalPagesMap] = useAtom(atom_totalPages);
    const [totalRowsMap, setTotalRowsMap] = useAtom(atom_totalRows);
    const [searchMap, setSearchMap] = useAtom(atom_search);
    const [filterMap, setFilterMap] = useAtom(atom_filter);
    const [orderByMap, setOrderByMap] = useAtom(atom_orderBy);
    const [directionMap, setDirectionMap] = useAtom(atom_direction);
    const [frameIdFilterMap, setFrameIdFilterMap] = useAtom(atom_frameIdFilter);
    const [scheduleFiltersMap, setScheduleFiltersMap] = useAtom(atom_scheduleFilters);
    const frameIdFilter = useMemo(
        () => frameIdFilterMap[lineItemId] || '',
        [frameIdFilterMap, lineItemId],
    );
    const screenData = useMemo(() => screenDataMap[lineItemId] || [], [lineItemId, screenDataMap]);
    const currentPage = useMemo(
        () => currentPageMap[lineItemId] || 1,
        [currentPageMap, lineItemId],
    );

    const scheduleFilters = useMemo(
        () => scheduleFiltersMap[lineItemId] || [],
        [scheduleFiltersMap, lineItemId],
    );
    const shouldReload = useMemo(() => reloadStateMap[lineItemId], [lineItemId, reloadStateMap]);
    const perPage = useMemo(() => perPageMap[lineItemId] || 100000, [lineItemId, perPageMap]);
    const totalPages = useMemo(() => totalPagesMap[lineItemId] || 1, [lineItemId, totalPagesMap]);
    const totalRows = useMemo(() => totalRowsMap[lineItemId] || 0, [lineItemId, totalRowsMap]);
    const theSearchTerm = useMemo(() => searchMap[lineItemId] || '', [lineItemId, searchMap]);
    const filter = useMemo(() => filterMap[lineItemId] || {}, [lineItemId, filterMap]);
    const orderBy = useMemo(() => orderByMap[lineItemId] || 'id', [lineItemId, orderByMap]);
    const direction = useMemo(() => directionMap[lineItemId] || 'asc', [lineItemId, directionMap]);
    const hasMounted = useRef(false);
    const { start: startScreen, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.screenFromSchedule,
    );
    const loading = useMemo(() => requestState === NetworkRequestState.InProgress, [requestState]);
    useEffect(() => {
        setAtomCampaignId(campaignId);
    }, [setAtomCampaignId, campaignId]);
    useEffect(() => {
        if (atomCampaignId !== campaignId) {
            setScreenDataMap({});
            setCurrentPageMap({});
            setPerPageMap({});
            setTotalPagesMap({});
            setTotalRowsMap({});
            setAtomCampaignId(campaignId);
            setSearchMap({});
            setFilterMap({});
            setOrderByMap({});
            setDirectionMap({});
        }
    }, [
        atomCampaignId,
        campaignId,
        setAtomCampaignId,
        setCurrentPageMap,
        setDirectionMap,
        setFilterMap,
        setOrderByMap,
        setPerPageMap,
        setScreenDataMap,
        setSearchMap,
        setTotalPagesMap,
        setTotalRowsMap,
    ]);

    const { setSelectedScreens, selectedScreens, setScheduleSearch } =
        useNewCampaignSchedule(campaignId);

    const handleScheduleChange = useCallback(
        event => {
            setScheduleFiltersMap(prev => ({
                ...prev,
                [lineItemId]: event.target.value,
            }));
        },
        [setScheduleFiltersMap, lineItemId],
    );

    const handleSelectScreen = useCallback(
        (item: Item) => {
            setSelectedScreens(prevSelected => {
                if (prevSelected.some(selectedItem => selectedItem.id === item.id)) {
                    return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
                }
                return [...prevSelected, item];
            });
        },
        [setSelectedScreens],
    );

    const handleSelectAllScreensClick = useCallback(() => {
        const allScreens = screenData
            .filter(
                screen =>
                    frameIdFilter.trim() === '' ||
                    frameIdFilter.split('\n').some(filterId => screen.frame_id?.includes(filterId)),
            )
            .map(screen => ({
                id: screen.id,
                name: screen.name,
                owner: screen.owner,
                frameId: screen.frame_id,
                schedules: screen.schedules.map(schedule => schedule.name),
                type: screen.type,
            }));

        const allSelected = allScreens.every(screen =>
            selectedScreens.some(selectedScreen => selectedScreen.id === screen.id),
        );

        if (allSelected) {
            setSelectedScreens(prevSelectedScreens =>
                prevSelectedScreens.filter(
                    selectedScreen => !allScreens.some(screen => screen.id === selectedScreen.id),
                ),
            );
        } else {
            setSelectedScreens(prevSelectedScreens =>
                uniqBy([...prevSelectedScreens, ...allScreens], 'id'),
            );
        }
    }, [screenData, selectedScreens, setSelectedScreens, frameIdFilter]);

    const allScreensSelected = useMemo(() => {
        const filteredScreens = screenData.filter(
            screen =>
                frameIdFilter.trim() === '' ||
                frameIdFilter.split('\n').some(filterId => screen.frame_id?.includes(filterId)),
        );
        const allScreens = filteredScreens.map(screen => screen.id);
        return allScreens.every(id =>
            selectedScreens.some(selectedScreen => selectedScreen.id === id),
        );
    }, [screenData, selectedScreens, frameIdFilter]);

    const screensIndeterminate = useMemo(
        () => selectedScreens.length > 0 && !allScreensSelected,
        [allScreensSelected, selectedScreens.length],
    );

    const getScreensFromLineItem = useCallback(
        async (
            lineItemId,
            page = 1,
            perPage = 100000,
            orderBy = 'id',
            direction: 'asc' | 'desc' = 'asc',
            filters = {},
            theSearchTerm = '',
        ) => {
            const options: ListSearchOptions = {
                page,
                perPage,
                orderBy,
                direction,
                showFilters: true,
                filters,
                filterFilters: true,
                includes: [],
                searchables: generateSearchColumnsData(
                    textSearchColumnsLineItemScreens,
                    theSearchTerm,
                    false,
                ),
            };
            const [err, success] = await to(startScreen(campaignId, lineItemId, options));
            if (!err && success?.data?.data) {
                const lineItem = scheduleLineItems.find(item => item.id === lineItemId);
                const owner = lineItem ? lineItem.owner : null;

                const newLineItems = success.data.data.map(item => ({
                    ...item,
                    schedules: item.schedules.data,
                    owner,
                }));
                setScreenDataMap(prev => ({ ...prev, [lineItemId]: newLineItems }));
                setTotalPagesMap(prev => ({
                    ...prev,
                    [lineItemId]: success?.data?.meta?.pagination?.total_pages,
                }));
                setTotalRowsMap(prev => ({
                    ...prev,
                    [lineItemId]: success?.data?.meta?.pagination?.total,
                }));
                setPerPageMap(prev => ({
                    ...prev,
                    [lineItemId]: success?.data?.meta?.pagination?.per_page,
                }));
                setCurrentPageMap(prev => ({
                    ...prev,
                    [lineItemId]: success?.data?.meta?.pagination?.current_page,
                }));
            }
        },
        [
            startScreen,
            campaignId,
            scheduleLineItems,
            setScreenDataMap,
            setTotalPagesMap,
            setTotalRowsMap,
            setPerPageMap,
            setCurrentPageMap,
        ],
    );
    const handleSortModelChange = useCallback(
        (newSortModel: GridSortModel) => {
            if (newSortModel.length > 0) {
                const { field } = newSortModel[0];
                const currentSortDirection: 'asc' | 'desc' = directionMap[lineItemId] || 'asc';
                const previousField = orderByMap[lineItemId];
                const previousSortDirection = directionMap[lineItemId];

                let newSortDirection: 'asc' | 'desc';

                if (field === previousField) {
                    newSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    newSortDirection = 'asc';
                }

                const shouldUpdateOrderBy = field !== previousField;
                const shouldUpdateDirection = newSortDirection !== previousSortDirection;

                if (shouldUpdateOrderBy || shouldUpdateDirection) {
                    if (shouldUpdateOrderBy) {
                        setOrderByMap(prev => ({ ...prev, [lineItemId]: field }));
                    }
                    if (shouldUpdateDirection) {
                        setDirectionMap(prev => ({ ...prev, [lineItemId]: newSortDirection }));
                    }
                }
            } else {
                setOrderByMap(prev => ({ ...prev, [lineItemId]: 'id' }));
                setDirectionMap(prev => ({ ...prev, [lineItemId]: 'asc' as 'asc' | 'desc' }));
            }
        },
        [setOrderByMap, setDirectionMap, lineItemId, directionMap, orderByMap],
    );

    const handleScreensPageSizeChange = useCallback(
        newPageSize => {
            setPerPageMap(prev => ({ ...prev, [lineItemId]: newPageSize }));
            getScreensFromLineItem(
                lineItemId,
                currentPage,
                newPageSize,
                orderBy,
                direction,
                filter,
                theSearchTerm,
            );
        },
        [
            setPerPageMap,
            getScreensFromLineItem,
            lineItemId,
            currentPage,
            orderBy,
            direction,
            theSearchTerm,
            filter,
        ],
    );

    const handleScreensPageChange = useCallback(
        newPage => {
            setCurrentPageMap(prev => ({ ...prev, [lineItemId]: newPage }));
            getScreensFromLineItem(
                lineItemId,
                newPage,
                perPage,
                orderBy,
                direction,
                filter,
                theSearchTerm,
            );
        },
        [
            setCurrentPageMap,
            getScreensFromLineItem,
            lineItemId,
            perPage,
            orderBy,
            direction,
            theSearchTerm,
            filter,
        ],
    );

    useEffect(() => {
        if (screenData.length === 0) {
            getScreensFromLineItem(
                lineItemId,
                currentPage,
                perPage,
                orderBy,
                direction,
                theSearchTerm,
            );
        }
    }, [
        currentPage,
        perPage,
        lineItemId,
        getScreensFromLineItem,
        screenData.length,
        screenData,
        orderBy,
        direction,
        theSearchTerm,
    ]);

    useEffect(() => {
        if (shouldReload) {
            getScreensFromLineItem(
                lineItemId,
                currentPage,
                perPage,
                orderBy,
                direction,
                theSearchTerm,
            );
            setReloadStateMap(prev => ({
                ...prev,
                [lineItemId]: false,
            }));
        }
    }, [
        lineItemId,
        currentPage,
        perPage,
        getScreensFromLineItem,
        orderBy,
        direction,
        theSearchTerm,
        shouldReload,
        setReloadStateMap,
    ]);
    useEffect(() => {
        const debouncedSearch = debounce(() => {
            getScreensFromLineItem(
                lineItemId,
                currentPage,
                perPage,
                orderBy,
                direction,
                filter,
                theSearchTerm,
            );
        }, 500);

        if (hasMounted.current) {
            debouncedSearch();
        } else {
            hasMounted.current = true;
        }

        return () => {
            debouncedSearch.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theSearchTerm, getScreensFromLineItem]);

    useEffect(() => {
        if (screenData.length === 0 && !screenDataMap[lineItemId]) {
            getScreensFromLineItem(
                lineItemId,
                currentPage,
                perPage,
                orderBy,
                direction,
                theSearchTerm,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItemId, getScreensFromLineItem, screenData, screenDataMap]);
    const ScheduleCell = ({
        id,
        type,
        schedules,
        t,
        handleDetach,
        setExpandedScheduleId,
        setScheduleSearch,
    }) => {
        const ScheduleDialogState = useBoolean(false);

        const ScheduleDialog = useMemo(
            () => (
                <DialogV2
                    open={ScheduleDialogState.value}
                    onClose={ScheduleDialogState.setFalse}
                    maxWidth="md"
                >
                    <Box p={2}>
                        <Typography variant="h6" gutterBottom>
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.scheduleDialogTitle')}
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                            {schedules.map(schedule => (
                                <Chip
                                    key={schedule.id}
                                    onClick={() => {
                                        setExpandedScheduleId(schedule.id);
                                        setScheduleSearch(schedule.name);
                                    }}
                                    onDelete={
                                        !schedule.is_file
                                            ? () => handleDetach(id, type, [schedule])
                                            : undefined
                                    }
                                    label={schedule.name}
                                />
                            ))}
                        </Box>
                        <Box mt={2}>
                            <Button
                                variant="text"
                                onClick={() => handleDetach(id, type, schedules)}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.table.clearAll')}
                            </Button>
                        </Box>
                    </Box>
                </DialogV2>
            ),
            [
                ScheduleDialogState.value,
                ScheduleDialogState.setFalse,
                t,
                schedules,
                setExpandedScheduleId,
                setScheduleSearch,
                handleDetach,
                id,
                type,
            ],
        );

        const buttonContent = useMemo(() => schedules.length, [schedules.length]);

        return (
            <Box>
                <Button variant="text" size="small" onClick={ScheduleDialogState.setTrue}>
                    {buttonContent}
                </Button>
                {ScheduleDialog}
            </Box>
        );
    };

    const screenColumns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol'),
                renderHeader: () => (
                    <Stack direction="row" gap={1} alignItems="center">
                        <Checkbox
                            checked={allScreensSelected}
                            indeterminate={screensIndeterminate}
                            onChange={handleSelectAllScreensClick}
                            onClick={e => e.stopPropagation()}
                        />
                        <Box fontWeight="bold">
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol')}
                        </Box>
                    </Stack>
                ),
                sortable: true,
                minWidth: 800,
                renderCell: ({ row }) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{ marginLeft: row.type === 'Display' ? 4 : 0 }}
                    >
                        <Checkbox
                            checked={selectedScreens.some(
                                selectedItem => selectedItem.id === row.id,
                            )}
                            onChange={() =>
                                handleSelectScreen({
                                    id: row.id,
                                    name: row.name,
                                    owner: row.owner,
                                    schedules: row.schedules.map(schedule => schedule.name),
                                    type: row.type,
                                    frameId: row.frame_id,
                                })
                            }
                        />

                        <Stack gap={2}>{row.name}</Stack>
                    </Stack>
                ),
            },
            {
                field: 'owner',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.ownerCol'),
                minWidth: 300,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.frameIdCol'),
                minWidth: 200,
            },
            {
                field: 'tv_region',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.tvRegion'),
                minWidth: 200,
            },
            {
                field: 'schedules',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.scheduleCol'),
                minWidth: 50,
                renderCell: ({ row }) => (
                    <ScheduleCell
                        id={row.id}
                        type={row.type}
                        schedules={row.schedules}
                        t={t}
                        handleDetach={handleDetach}
                        setExpandedScheduleId={setExpandedScheduleId}
                        setScheduleSearch={setScheduleSearch}
                    />
                ),
            },
            // {
            //     field: 'environment',
            //     headerName: '',
            // },
            // {
            //     field: 'resolutions',
            //     headerName: '',
            // },
            // {
            //     field: 'size',
            //     headerName: '',
            // },
        ],
        [
            t,
            allScreensSelected,
            screensIndeterminate,
            handleSelectAllScreensClick,
            selectedScreens,
            handleSelectScreen,
            setExpandedScheduleId,
            setScheduleSearch,
            handleDetach,
        ],
    );

    const filteredScreenDataByFrameId = useMemo(() => {
        if (frameIdFilter.trim() === '') {
            return screenData;
        }

        const frameIdFilters = frameIdFilter
            .split('\n')
            .map(frameId => frameId.trim())
            .filter(Boolean);

        return screenData.filter(screen =>
            frameIdFilters.some(filterId => screen.frame_id?.includes(filterId)),
        );
    }, [frameIdFilter, screenData]);

    const scheduleFilterOptions = useMemo(() => {
        const allSchedules = filteredScreenDataByFrameId.flatMap(screen => screen.schedules);
        const uniqueSchedules = [
            ...new Map(allSchedules.map(schedule => [schedule.id, schedule])).values(),
        ];

        return uniqueSchedules.map(schedule => ({
            label: schedule.name,
            value: schedule.id,
        }));
    }, [filteredScreenDataByFrameId]);

    const filteredScreenData = useMemo(() => {
        let filteredData = filteredScreenDataByFrameId;

        if (scheduleFilters.length > 0) {
            filteredData = filteredData.filter(screen =>
                screen.schedules.some(schedule => scheduleFilters.includes(schedule.id)),
            );
        }

        return filteredData;
    }, [filteredScreenDataByFrameId, scheduleFilters]);

    return (
        <Box flex={1} overflow="hidden" mt={1} display="flex" minHeight="100%" width="100%">
            <Stack gap={1} mb={2} width="100%" height="100%">
                <AutoGrid gap={2}>
                    <TextField
                        size="small"
                        variant="outlined"
                        value={theSearchTerm}
                        onChange={e =>
                            setSearchMap(prev => ({ ...prev, [lineItemId]: e.target.value }))
                        }
                        placeholder={t(
                            'Modules.Main.Campaigns.CampaignSchedule.filters.searchLabel',
                        )}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box mt={-1}>
                        <TextAreaField
                            size="small"
                            variant="outlined"
                            value={frameIdFilter}
                            onChange={e =>
                                setFrameIdFilterMap(prev => ({
                                    ...prev,
                                    [lineItemId]: e.target.value,
                                }))
                            }
                            placeholder={t(
                                'Modules.Main.Campaigns.CampaignSchedule.filters.frameIdFilter',
                            )}
                            showCharacterCount={false}
                        />
                        <Box display="flex" justifyContent="space-between" mt={1}>
                            <Typography color="grey" variant="body2">
                                {`${
                                    frameIdFilter.split('\n').filter(frameId => frameId.trim())
                                        .length
                                } ${t('Modules.Main.Campaigns.CampaignSchedule.filters.frameIds')}`}
                            </Typography>
                            {frameIdFilter.split('\n').filter(frameId => frameId.trim()).length >
                                0 && (
                                <Typography color="grey" variant="body2">
                                    {`${filteredScreenData.length} ${t(
                                        'Modules.Main.Campaigns.CampaignSchedule.filters.results',
                                    )}`}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box minWidth="20rem" maxWidth="20rem">
                        <SelectField
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.filters.scheduleFilter',
                            )}
                            options={scheduleFilterOptions}
                            value={scheduleFilters}
                            onChange={handleScheduleChange}
                            multiple
                            clearAll
                        />
                    </Box>
                </AutoGrid>

                <DataGridPro
                    columns={screenColumns}
                    rows={filteredScreenData}
                    disableColumnResize={false}
                    loading={loading || screenData.length === 0}
                    sortModel={[{ field: orderBy, sort: direction }]}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Footer: ScreenFooter,
                    }}
                    componentsProps={{
                        footer: {
                            currentPage,
                            totalPages,
                            rowCount: totalRows,
                            perPage,
                            onPageChange: handleScreensPageChange,
                            onPageSizeChange: handleScreensPageSizeChange,
                            loading,
                        } as GridFooterContainerProps,
                    }}
                />
            </Stack>
        </Box>
    );
};

export default ScreenTable;
