import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ByFieldChartProps,
    FieldReportType,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { ReportConfig, ReportDateTimeframe } from 'c-reports/Types';
import {
    CampaignNoticeKey,
    ElasticSearchAvailableFilters,
    ElasticSearchGroupByFieldResponse,
    EsPCAReportField,
    PCAReportByFieldResponse,
    PCAReportField,
} from '@uniled/api-sdk';
import apiClient from 'c-data/apiClient';
import { format } from 'date-fns';
import { useDateUtils } from 'c-hooks';
import {
    OverallCharts,
    OverallLeaderboardCharts,
} from 'c-main/Components/Campaign/CampaignReports/Views/ByField/ChartTypes';
import {
    groupedByFilterToLeaderboardData,
    performanceStatsToOldCampaignStats,
} from './Transformers';

type Props = ByFieldChartProps & {
    onFiltersUpdated: (filters: ElasticSearchAvailableFilters) => void;
    reportType: FieldReportType;
    refineFilters: boolean;
    fieldFilters?: Record<string, string[]>;
    onResponse?: (response, startDate: Date, endDate: Date, timeframe: ReportDateTimeframe) => void;
};

const ESGroupByField: React.FC<Props> = props => {
    const {
        campaignId,
        environment,
        source,
        metric,
        onFiltersUpdated,
        fieldFilters,
        field,
        refineFilters,
        reportType,
        onResponse,
    } = props;
    const [pcaData, setPcaData] = useState<ElasticSearchGroupByFieldResponse>(null);
    useEffect(() => {
        onFiltersUpdated(pcaData?.filters);
    }, [pcaData?.filters, onFiltersUpdated]);

    const { formatDateString } = useDateUtils();
    const leaderboardData = useMemo<PCAReportByFieldResponse>(() => {
        const data = groupedByFilterToLeaderboardData({
            metric,
            field: field as EsPCAReportField,
            data: pcaData,
            withResourceId: true,
        });

        /**
         * the keys for each date are returned in `yyyy-MM-dd` date format.
         * we want to reformat the keys so they use the application's date formatting for consistency
         */
        if (field === EsPCAReportField.StartDate || field === EsPCAReportField.EndDate) {
            return {
                reportData: Object.entries(data.reportData).reduce(
                    (newData, [dateKey, values]) => ({
                        ...newData,
                        [formatDateString({ date: dateKey })]: values,
                    }),
                    {},
                ),
            };
        }
        return data;
    }, [field, metric, pcaData, formatDateString]);

    const fetchData: ReportConfig<ElasticSearchGroupByFieldResponse>['fetchData'] = useCallback(
        (start, end, timeframe) =>
            apiClient.ReportsElasticSearch.pcaGroupByField({
                campaignId,
                showStats: true,
                startDate:
                    timeframe === ReportDateTimeframe.All
                        ? undefined
                        : `${format(start, 'yyyy-MM-dd')} 00:00:00`,
                endDate:
                    timeframe === ReportDateTimeframe.All
                        ? undefined
                        : `${format(end, 'yyyy-MM-dd')} 23:59:59`,
                field: (field as EsPCAReportField) ?? EsPCAReportField.StartDate,
                environment,
                source,
                fieldFilters,
                dataStatus: fieldFilters?.[PCAReportField.DataStatus] as CampaignNoticeKey[],
            }),
        [campaignId, environment, field, fieldFilters, source],
    );

    const onDataUpdated: ReportConfig<any>['onDataUpdated'] = useCallback(
        (data: ElasticSearchGroupByFieldResponse, start, end, timeframe) => {
            setPcaData(data);
            if (data?.campaign_stats) {
                onResponse(
                    { campaignStats: performanceStatsToOldCampaignStats(data.campaign_stats) },
                    start,
                    end,
                    timeframe,
                );
            }
        },
        [onResponse],
    );

    const actualChartProps = useMemo<ByFieldChartProps>(
        () => ({
            ...props,
            searchTerm: '',
            pcaData: leaderboardData,
            reportWrapperProps: {
                ...props.reportWrapperProps,
                fetchData,
                onDataUpdated,
            },
            allFields: Object.keys(leaderboardData?.reportData ?? {}),
            actualSelectedFieldParts: Object.keys(leaderboardData?.reportData ?? {}),
        }),
        [props, fetchData, onDataUpdated, leaderboardData],
    );

    return (
        <>
            {reportType === FieldReportType.OverallLeaderboard && (
                <OverallLeaderboardCharts
                    {...actualChartProps}
                    filters={fieldFilters || {}}
                    refineFilters={refineFilters}
                />
            )}
            {reportType === FieldReportType.Overall && <OverallCharts {...actualChartProps} />}
        </>
    );
};

export default ESGroupByField;
