import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCommonTranslation } from 'c-translation';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';

import { useAPIClientRequest } from 'c-hooks';

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, CircularProgress, InputAdornment, Stack, Tab, Typography } from '@mui/material';
import { Alert, Button, Chip, DialogV2, SelectField, TextAreaField, TextField } from 'c-components';
import { UseBoolean, useBoolean } from 'react-hanger';
import { TabContext, TabList } from '@mui/lab';
import { Search } from '@mui/icons-material';
import { MediaTable as MediaTableList } from '@uniled/api-sdk';

import PopSettingsCreativesSearch from 'c-main/Components/Schedule/Components/PopSettings/PopSettingsCreativesSearch';
import UseAPIClientRequest from '../../../../../../Hooks/useAPIClientRequest';

type Props = {
    campaignId: number;
    addNameState: UseBoolean;
};

const tabLabels = [
    { label: 'ID', field: 'id', translation: 'id' },
    { label: 'Name', field: 'name', translation: 'name' },
    { label: 'Media Ext ID', field: 'media_extid', translation: 'mediaExtId' },
    { label: 'Creative Ref', field: 'creative_ref', translation: 'creativeRef' },
    { label: 'Campaign Name', field: 'campaign_name', translation: 'campaignName' },
    { label: 'Campaign Ext ID', field: 'campaign_extid', translation: 'campaignExtId' },
    { label: 'Campaign Ref', field: 'campaign_ref', translation: 'campaignRef' },
];

const MediaTable: React.FC<Props> = ({ campaignId, addNameState }) => {
    const t = useCommonTranslation();
    const [activeTab, setActiveTab] = useState('0');
    const [paramsData, setParamsData] = useState([]);
    const [tabContent, setTabContent] = useState<{ [key: string]: string[] }>({});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');

    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            setActiveTab(newValue.toString());
        },
        [setActiveTab],
    );

    const cols: GridColDef[] = [
        {
            field: 'id',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.id'),
            width: 90,
        },
        {
            field: 'name',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.name'),
            flex: 1,
        },
        {
            field: 'first_seen',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.firstSeen'),
            width: 170,
        },
        {
            field: 'last_seen',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.lastSeen'),
            width: 170,
        },
        {
            field: 'parameter',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.parameters'),
            flex: 1,
            renderCell: ({ row }) => (
                <Stack gap={1} direction="row">
                    {row?.parameter?.map((param: any) => {
                        if (param != null) {
                            return (
                                <Chip
                                    key={param?.id}
                                    label={`${param.parameter}: ${param.identifier}`}
                                />
                            );
                        }
                        return '';
                    })}
                </Stack>
            ),
        },
    ];
    const paramDialogState = useBoolean(false);
    const [tableData, setTableData] = useState<MediaTableList[]>([]);
    const { start, data, isLoading, error } = UseAPIClientRequest(
        ApiClient.Entities.Campaign.mediaList,
    );
    const {
        start: getParamStart,
        error: paramsGetError,
        isLoading: getParmasLoading,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.GetMediaParameter);
    const {
        start: updateParamsStart,
        error: updateError,
        isLoading: updateLoading,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.UpdateMediaParameter);
    const {
        start: delParam,
        error: delError,
        isLoading: delLoading,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.DeleteMediaParameter);

    const getMediaListData = useCallback(async () => {
        const [err, succ] = await to(start(campaignId));
        if (succ) {
            const uniqueData = Array.from(
                new Map(succ.data.media.map(item => [item.id, item])).values(),
            ); // Ensure no duplicates
            setTableData(uniqueData);
        }
    }, [start, campaignId]);
    useEffect(() => {
        if (addNameState.value) {
            getMediaListData();
            addNameState.setFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNameState.value]);
    useEffect(() => {
        getMediaListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getParams = useCallback(async () => {
        const [err, succ] = await to(getParamStart(campaignId));
        if (succ) {
            setParamsData(succ.data.data);
        }
    }, [campaignId, getParamStart]);

    const refreshData = useCallback(async () => {
        await to(getMediaListData());
        await to(getParams());
    }, [getMediaListData, getParams]);

    const deleteParam = useCallback(
        async (id: number) => {
            const [err, succ] = await to(delParam(campaignId, id));
            if (succ) {
                await refreshData();
            }
        },
        [campaignId, delParam, refreshData],
    );

    const handleDialogOpen = useCallback(() => {
        setTabContent({});
        getParams();
        paramDialogState.setTrue();
    }, [paramDialogState, getParams]);

    const handleApplyChanges = useCallback(async () => {
        const updatedFields = Object.keys(tabContent).reduce((acc, field) => {
            if (tabContent[field] && tabContent[field].length > 0) {
                acc[field] = tabContent[field];
            }
            return acc;
        }, {} as { [key: string]: string[] });
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, identifiers] of Object.entries(updatedFields)) {
            const payload = {
                parameter: field as
                    | 'id'
                    | 'name'
                    | 'media_extid'
                    | 'creative_ref'
                    | 'campaign_name'
                    | 'campaign_extid'
                    | 'campaign_ref',
                identifier: identifiers,
            };

            // eslint-disable-next-line no-await-in-loop
            await to(updateParamsStart(campaignId, payload));
        }
        paramDialogState.setFalse();
        await to(refreshData());
    }, [tabContent, paramDialogState, refreshData, updateParamsStart, campaignId]);

    const handleTabContentChange = useCallback((field: string, value: string) => {
        setTabContent(prev => ({
            ...prev,
            [field]: value.split('\n').filter(v => v.trim()), // Remove empty lines
        }));
    }, []);

    const filteredTableData = useMemo(
        () =>
            tableData.filter(row => {
                const matchesSearch = Object.values(row).some(value =>
                    value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
                );

                const matchesParameter = selectedParameter
                    ? row.parameter?.some(param => param?.parameter === selectedParameter)
                    : true;

                return matchesSearch && matchesParameter;
            }),
        [tableData, searchQuery, selectedParameter],
    );

    const resetButton = useMemo(
        () => (
            <Button size="small" onClick={() => setSearchQuery('')}>
                <Typography variant="caption">
                    {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.clear')}
                </Typography>
            </Button>
        ),
        [t],
    );

    return (
        <>
            <DialogV2
                onClose={paramDialogState.setFalse}
                open={paramDialogState.value}
                title="Modules.Main.Campaigns.CampaignSchedule.mediaTable.editParams"
                maxWidth="xl"
            >
                {paramsGetError && <Alert severity="error">{String(paramsGetError)}</Alert>}
                <TabContext value={activeTab.toString()}>
                    <TabList onChange={handleTabChange}>
                        {tabLabels.map((tab, index) => (
                            <Tab key={tab.label} label={tab.label} value={index.toString()} />
                        ))}
                    </TabList>

                    <Box mt={2}>
                        {tabLabels.map((tab, index) => (
                            <Box
                                key={tab.label}
                                hidden={activeTab !== index.toString()}
                                display={activeTab === index.toString() ? 'block' : 'none'}
                            >
                                <Typography variant="body1" gutterBottom>
                                    {t(
                                        `Modules.Main.Campaigns.CampaignSchedule.popSettings.${tab.translation}`,
                                    )}
                                </Typography>
                                <TextAreaField
                                    multiline
                                    fullWidth
                                    rows={5}
                                    value={tabContent[tab.field]?.join('\n') || ''}
                                    onChange={e =>
                                        handleTabContentChange(tab.field, e.target.value)
                                    }
                                    variant="outlined"
                                />

                                <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                                    {getParmasLoading || delLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        paramsData
                                            ?.filter(param => param.parameter === tab.field)
                                            .map(param => (
                                                <Chip
                                                    key={`${param.id}`}
                                                    label={param.identifier}
                                                    onDelete={() => deleteParam(param.id)}
                                                />
                                            ))
                                    )}
                                </Box>
                            </Box>
                        ))}
                        {delError && <Alert severity="error">{String(delError)}</Alert>}
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        {!updateLoading ? (
                            <Button variant="contained" onClick={handleApplyChanges}>
                                {t(
                                    'Modules.Main.Campaigns.CampaignSchedule.mediaTable.applyChanges',
                                )}
                            </Button>
                        ) : (
                            <CircularProgress size={20} />
                        )}
                    </Box>
                </TabContext>
                {updateError && <Alert severity="error">{String(updateError)}</Alert>}
            </DialogV2>
            <Box p={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                            variant="outlined"
                            placeholder={t(
                                'Modules.Main.Campaigns.CampaignSchedule.mediaTable.search',
                            )}
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">{resetButton}</InputAdornment>
                                ),
                            }}
                            sx={{ width: '300px' }}
                        />
                        <SelectField
                            value={selectedParameter}
                            onChange={e => setSelectedParameter(e.target.value as string)}
                            options={[
                                { label: 'All', value: '' },
                                { label: 'Media Ext ID', value: 'media_extid' },
                                { label: 'Creative Ref', value: 'creative_ref' },
                                { label: 'Campaign Name', value: 'campaign_name' },
                                { label: 'Campaign Ext ID', value: 'campaign_extid' },
                                { label: 'Campaign Ref', value: 'campaign_ref' },
                            ]}
                            size="small"
                            placeholder={t(
                                'Modules.Main.Campaigns.CampaignSchedule.mediaTable.filterByParameter',
                            )}
                            sx={{ width: '200px' }}
                        />
                    </Box>
                    <Stack direction="row" gap={1}>
                        <Button variant="contained" onClick={handleDialogOpen}>
                            {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.editParams')}
                        </Button>
                        <PopSettingsCreativesSearch
                            campaignId={campaignId}
                            mediaTableData={tableData}
                            onApplyChanges={refreshData}
                        />
                    </Stack>
                </Box>
                <DataGridPro
                    columns={cols}
                    rows={filteredTableData}
                    loading={isLoading}
                    autoHeight
                />
                {error && <Alert severity="error">{String(error)}</Alert>}
            </Box>
        </>
    );
};

export default MediaTable;
