import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Alert as MuiAlert,
    Box,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { apiClient, useEntityData, User } from 'c-data';
import { Alert, AutoGrid, Button, EntityField } from 'c-components';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Campaign, Client, PermissionName } from '@uniled/api-sdk';
import { useAPIClientRequest, useDateUtils } from 'c-hooks';
import { UserProfilePopOver } from 'c-admin/Components';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import { CheckCircle } from '@mui/icons-material';
import { cloudDownloadIcon, deleteIcon, publishIcon, refreshIcon } from 'c-main/icons';
import ApiClient from 'c-data/apiClient';

type Props = {
    id: number;
    buttons?: boolean;
};

const clientReadPerms = [PermissionName.UniledportalClientRead];
const dateReadPerms = [PermissionName.UniledportalDateRead];
function formatDateString(dateString: string) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const timeZoneCode = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
        .formatToParts(date)
        .find(part => part.type === 'timeZoneName').value;

    return `${day}/${month}/${year} ${hours}:${minutes} ${timeZoneCode}`;
}

const unixDateFormatToLocal = (unixDate: number) => {
    const date = new Date(unixDate * 1000);
    return date.toLocaleString();
};

const CampaignOverviewDetails: React.FC<Props> = ({ id, buttons = false }) => {
    const { start, data } = useAPIClientRequest(ApiClient.Entities.Campaign.get);
    useEffect(() => {
        start(id, [
            'agency',
            'market',
            'creatives',
            'client',
            'creatives.resolution',
            'creatives.file',
            'date',
            'budgetSummary',
            'budgetSummary.lineItems',
            'budgetSummaries',
            'budgetSummaries.lineItems',
            'notices',
            'errors',
            'creativeThumbnails',
            'bookingSummaries',
            'popShotsCount',
            'missingDisplaysCount',
            'additionalContacts',
            // 'budgetSummaries.lineItems.lineItem',
        ]);
    }, [id, start]);
    const campaign = useMemo(() => data?.data?.data, [data]);
    const { formatDateStringIgnoreTime } = useDateUtils();
    const {
        start: runClientStart,
        requestState: runClientState,
        error: runClientError,
        reset: resetRunClient,
    } = useAPIClientRequest(apiClient.Reports.pcaRunReport);

    const {
        start: downloadClientStart,
        requestState: downloadClientState,
        error: downloadClientError,
        reset: resetDownloadClient,
    } = useAPIClientRequest(apiClient.Entities.Campaign.pcaExport);

    const {
        start: deleteClientStart,
        requestState: deleteClientState,
        error: deleteClientError,
        reset: resetDeleteClient,
    } = useAPIClientRequest(apiClient.Reports.pcaDeleteReport);

    const {
        start: runInternalStart,
        requestState: runInternalState,
        error: runInternalError,
        reset: resetRunInternal,
    } = useAPIClientRequest(apiClient.Reports.pcaRunReport);

    const {
        start: downloadInternalStart,
        requestState: downloadInternalState,
        error: downloadInternalError,
        reset: resetDownloadInternal,
    } = useAPIClientRequest(apiClient.Entities.Campaign.pcaExport);

    const {
        start: deleteInternalStart,
        requestState: deleteInternalState,
        error: deleteInternalError,
        reset: resetDeleteInternal,
    } = useAPIClientRequest(apiClient.Reports.pcaDeleteReport);
    const {
        start: startPublish,
        requestState: publishState,
        error: publicError,
        reset: publicReset,
    } = useAPIClientRequest(apiClient.Reports.publishReport);
    const {
        start: channelFrameGetStart,
        data: getData,
        error: channelFrameGetError,
    } = useAPIClientRequest(apiClient.Entities.Campaign.channelFrameGet);
    const {
        start: channelFramePostStart,
        requestState: channelFramePostRequestState,
        data: postData,
        error: channelFramePostError,
        reset: channelFramePostReset,
    } = useAPIClientRequest(apiClient.Entities.Campaign.channelFramePost);

    useEffect(() => {
        if (buttons) {
            channelFrameGetStart(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttons, id]);

    const handleAction = useCallback(
        async (
            action: 'run' | 'download' | 'delete' | 'publish',
            environment: 'live' | 'sandbox',
        ) => {
            let apiCall;
            let resetFn;
            let error = null;

            if (action === 'run') {
                apiCall = environment === 'live' ? runClientStart : runInternalStart;
                resetFn = environment === 'live' ? resetRunClient : resetRunInternal;
            }
            if (action === 'download') {
                apiCall = environment === 'live' ? downloadClientStart : downloadInternalStart;
                resetFn = environment === 'live' ? resetDownloadClient : resetDownloadInternal;
            }
            if (action === 'delete') {
                apiCall = environment === 'live' ? deleteClientStart : deleteInternalStart;
                resetFn = environment === 'live' ? resetDeleteClient : resetDeleteInternal;
            }
            if (action === 'publish' && environment === 'sandbox') {
                apiCall = startPublish;
                resetFn = publicReset;
            }

            const formData = new FormData();
            formData.append('environment', environment);

            if (action === 'download') {
                const [err, response] = await to(
                    apiCall(Number(id), formData, { responseType: 'blob' }),
                );

                if (response) {
                    const blob = new Blob([(response as any).data], { type: 'application/zip' }); // Correct MIME type for ZIP

                    const contentDisposition = (response as any).headers['content-disposition'];
                    let filename = 'download.zip';

                    if (contentDisposition && contentDisposition.includes('filename=')) {
                        filename = contentDisposition
                            .split('filename=')[1]
                            .trim()
                            .replace(/['"]/g, '');
                    }

                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }

                error = err;
            } else if (action === 'run') {
                const [err] = await to(apiCall({ id, environment, consumerType: 'full' }));
                error = err;
            } else if (action === 'delete') {
                const [err] = await to(apiCall({ id, environment }));
                error = err;
            } else if (action === 'publish') {
                const [err] = await to(apiCall({ id }));
                error = err;
            }

            if (error) {
                setTimeout(() => {
                    resetFn();
                }, 10000);
            } else {
                setTimeout(() => {
                    resetFn();
                }, 2000);
            }
        },
        [
            runClientStart,
            runInternalStart,
            resetRunClient,
            resetRunInternal,
            downloadClientStart,
            downloadInternalStart,
            resetDownloadClient,
            resetDownloadInternal,
            deleteClientStart,
            deleteInternalStart,
            resetDeleteClient,
            resetDeleteInternal,
            startPublish,
            publicReset,
            id,
        ],
    );
    const t = useCommonTranslation();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (
            channelFramePostRequestState === NetworkRequestState.Success ||
            channelFramePostRequestState === NetworkRequestState.Error
        ) {
            const resetTimer = setTimeout(
                channelFramePostReset,
                channelFramePostRequestState === NetworkRequestState.Success ? 2000 : 5000,
            );
            return () => clearTimeout(resetTimer);
        }
    }, [channelFramePostRequestState, channelFramePostReset]);

    return useMemo(
        () => (
            <Box>
                <AutoGrid spacing={2}>
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.bookedByHeader" />
                        }
                        value={<BookedBy campaign={campaign} />}
                    />
                    <IfHasAllPermissions permissions={clientReadPerms}>
                        <Block
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.brandHeader" />
                            }
                            value={
                                <EntityField<Client>
                                    id={campaign?.client_id}
                                    entityName="Client"
                                    field="name"
                                />
                            }
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions permissions={dateReadPerms}>
                        <Block
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.duration" />
                            }
                            value={
                                <>
                                    {formatDateStringIgnoreTime({
                                        date: campaign?.date?.date_start,
                                    })}{' '}
                                    -{' '}
                                    {formatDateStringIgnoreTime({
                                        date: campaign?.date?.date_end,
                                    })}
                                </>
                            }
                        />
                    </IfHasAllPermissions>
                    {buttons && (
                        <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                            <Stack direction="row" gap={5} pl={6}>
                                <Box>
                                    <Stack direction="row">
                                        <Typography color="grey.500">
                                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.client" />
                                        </Typography>
                                        <ButtonWithState
                                            action="run"
                                            environment="live"
                                            handleAction={handleAction}
                                            requestState={runClientState}
                                        />
                                        <ButtonWithState
                                            action="download"
                                            environment="live"
                                            handleAction={handleAction}
                                            requestState={downloadClientState}
                                            disabled={!campaign?.pca_available.live}
                                        />
                                        <ButtonWithState
                                            action="delete"
                                            environment="live"
                                            handleAction={handleAction}
                                            requestState={deleteClientState}
                                            disabled={!campaign?.pca_available.live}
                                        />
                                    </Stack>
                                    <Typography>
                                        {campaign?.pca_available.live &&
                                            formatDateString(campaign?.pca_available.live)}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Stack direction="row">
                                        <Typography color="grey.500">
                                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.internal" />
                                        </Typography>
                                        <ButtonWithState
                                            action="run"
                                            environment="sandbox"
                                            handleAction={handleAction}
                                            requestState={runInternalState}
                                        />
                                        <ButtonWithState
                                            action="download"
                                            environment="sandbox"
                                            handleAction={handleAction}
                                            requestState={downloadInternalState}
                                            disabled={!campaign?.pca_available.sandbox}
                                        />
                                        <ButtonWithState
                                            action="delete"
                                            environment="sandbox"
                                            handleAction={handleAction}
                                            requestState={deleteInternalState}
                                            disabled={!campaign?.pca_available.sandbox}
                                        />
                                        {campaign?.pca_available.sandbox && (
                                            <ButtonWithState
                                                action="publish"
                                                environment="sandbox"
                                                handleAction={handleAction}
                                                requestState={publishState}
                                                disabled={!campaign?.pca_available.sandbox}
                                            />
                                        )}
                                    </Stack>
                                    <Typography>
                                        {campaign?.pca_available.sandbox &&
                                            formatDateString(campaign?.pca_available.sandbox)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Stack direction="row" spacing={2}>
                                        <Typography color="grey.500">
                                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.unlinked" />
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() => channelFramePostStart(id)}
                                        >
                                            {channelFramePostRequestState ===
                                            NetworkRequestState.InProgress ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <>{refreshIcon}</>
                                            )}
                                        </IconButton>
                                        {getData?.data?.url && (
                                            <a href={getData?.data.url} download>
                                                <IconButton color="primary" size="small">
                                                    {cloudDownloadIcon}
                                                </IconButton>
                                            </a>
                                        )}
                                    </Stack>
                                    {getData?.data?.last_modified && (
                                        <Typography>
                                            {unixDateFormatToLocal(getData?.data?.last_modified)}
                                        </Typography>
                                    )}
                                    {channelFramePostRequestState ===
                                        NetworkRequestState.Success && (
                                        <Alert severity="success">{postData?.data?.alert}</Alert>
                                    )}
                                    {channelFrameGetError && (
                                        <Alert severity="error">
                                            {String(channelFrameGetError)}
                                        </Alert>
                                    )}
                                    {channelFramePostError && (
                                        <Alert severity="error">
                                            {String(channelFramePostError)}
                                        </Alert>
                                    )}
                                </Box>
                            </Stack>
                            {(downloadClientState === NetworkRequestState.Success ||
                                downloadInternalState === NetworkRequestState.Success) && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.downloadSuccess')}
                                </Alert>
                            )}
                            {runInternalError && (
                                <Alert severity="error" variant="outlined">
                                    {String(runInternalError)}
                                </Alert>
                            )}
                            {runClientError && (
                                <Alert severity="error" variant="outlined">
                                    {String(runClientError)}
                                </Alert>
                            )}
                            {downloadInternalError && (
                                <Alert severity="error" variant="outlined">
                                    {String(downloadInternalError)}
                                </Alert>
                            )}
                            {downloadClientError && (
                                <Alert severity="error" variant="outlined">
                                    {String(downloadClientError)}
                                </Alert>
                            )}
                            {deleteInternalError && (
                                <Alert severity="error" variant="outlined">
                                    {String(deleteInternalError)}
                                </Alert>
                            )}
                            {deleteClientError && (
                                <Alert severity="error" variant="outlined">
                                    {String(deleteClientError)}
                                </Alert>
                            )}
                            {deleteInternalState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.deleteSuccess')}
                                </Alert>
                            )}
                            {deleteClientState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.deleteSuccess')}
                                </Alert>
                            )}
                            {runInternalState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.runSuccess')}
                                </Alert>
                            )}
                            {runClientState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.runSuccess')}
                                </Alert>
                            )}
                            {publishState === NetworkRequestState.Success && (
                                <Alert severity="success" variant="outlined">
                                    {t('Modules.Main.Campaigns.Overview.Cad.publishSuccess')}
                                </Alert>
                            )}
                            {publicError && (
                                <Alert severity="error" variant="outlined">
                                    {String(publicError)}
                                </Alert>
                            )}
                        </IfHasAllPermissions>
                    )}
                </AutoGrid>
                {campaign?.flags?.under_configuration === true && (
                    <MuiAlert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.Overview.underConstructionWarning" />
                    </MuiAlert>
                )}
            </Box>
        ),
        [
            campaign,
            formatDateStringIgnoreTime,
            buttons,
            handleAction,
            runClientState,
            runClientError,
            downloadClientState,
            downloadClientError,
            deleteClientState,
            deleteClientError,
            runInternalState,
            runInternalError,
            downloadInternalState,
            downloadInternalError,
            deleteInternalState,
            deleteInternalError,
            publishState,
            publicError,
            channelFramePostRequestState,
            getData?.data.url,
            getData?.data?.last_modified,
            postData?.data?.alert,
            channelFrameGetError,
            channelFramePostError,
            t,
            channelFramePostStart,
            id,
        ],
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <Box>
        <Typography color="grey.500">{title}</Typography>
        <Typography sx={{ maxWidth: '20em', wordBreak: 'break-word' }} component="div">
            {value}
        </Typography>
    </Box>
);

const ButtonWithState = ({
    action,
    environment,
    handleAction,
    requestState,
    disabled,
}: {
    action: 'run' | 'download' | 'delete' | 'publish';
    environment: string;
    handleAction: (action: string, environment: string) => Promise<void>;
    requestState: NetworkRequestState;
    disabled?: boolean;
}) => {
    const isLoading = requestState === NetworkRequestState.InProgress;
    const isSuccess = requestState === NetworkRequestState.Success;

    const getIcon = () => {
        switch (action) {
            case 'run':
                return refreshIcon;
            case 'download':
                return cloudDownloadIcon;
            case 'delete':
                return deleteIcon;
            case 'publish':
                return publishIcon;
            default:
                return null;
        }
    };

    return (
        <Stack>
            <IconButton
                onClick={() => handleAction(action, environment)}
                disabled={isLoading || isSuccess || disabled}
                color={action === 'delete' ? 'error' : 'primary'}
                size="small"
            >
                {isLoading ? (
                    <CircularProgress size={20} />
                ) : isSuccess ? (
                    <CheckCircle color="success" />
                ) : (
                    getIcon()
                )}
            </IconButton>
        </Stack>
    );
};

const BookedBy: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
    const userId = campaign?.user_id;
    const { getById, getLoadingById } = useEntityData<User>('User');
    const user = getById({ id: userId });

    const isLoading = useMemo(
        () => getLoadingById(userId).state === NetworkRequestState.InProgress,
        [userId, getLoadingById],
    );
    const username = useMemo(
        () =>
            user ? (
                <UserProfilePopOver userId={userId}>
                    <Box>
                        <Button variant="text">{user?.name}</Button>
                    </Box>
                </UserProfilePopOver>
            ) : null,
        [user, userId],
    );
    if (!user && isLoading)
        return (
            <>
                <CircularProgress size={15} />
            </>
        );

    if (!user) return <>-</>;

    return <>{username}</>;
};
export default CampaignOverviewDetails;
