import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, Checkbox, CircularProgress } from '@mui/material';
import { Alert, AutoGrid, Button, DialogV2, SelectField, TextField } from 'c-components';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';
import DateRangeField from 'c-components/Forms/DateRangeField';
import EntityAutocomplete from 'c-components/EntityAutocomplete';
import { Search } from '@mui/icons-material';

import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { MediaTable, PopDisplays_Media } from '@uniled/api-sdk';
import { generateSearchColumnsData } from 'c-pagination';
import { format, parseISO } from 'date-fns';
import to from 'await-to-js';

type Props = {
    campaignId: number;
    mediaTableData: MediaTable[];
    onApplyChanges?: () => void;
};

const PopSettingsCreativesSearch: React.FC<Props> = ({
    campaignId,
    mediaTableData,
    onApplyChanges,
}) => {
    const t = useCommonTranslation();
    const creativesDialogState = useBoolean(false);

    const [rows, setRows] = useState<PopDisplays_Media[]>([]);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [selectionModel, setSelectionModel] = useState<Set<number>>(new Set());
    const [intermediateModel, setIntermediateModel] = useState<Set<number>>(new Set());
    const [theSearchTerm, setTheSearchTerm] = useState('');
    const [selectedSearchField, setSelectedSearchField] = useState('name');
    const [logImportSources, setLogImportSources] = useState<string[]>([]);
    const [[start, end], setDateRange] = useState<string[]>([]);
    const shouldFetch = useBoolean(true);

    const {
        start: fetchMedia,
        isLoading,
        error,
    } = useAPIClientRequest(ApiClient.Entities.PopDisplays_Media.list);
    const {
        start: updateParams,
        isLoading: updateLoading,
        error: updateError,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.UpdateMediaParameter);
    const {
        start: deleteParams,
        isLoading: delLoading,
        error: delError,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.DeleteMultipleMediaParameters);

    const [deselectedModel, setDeselectedModel] = useState<Set<number>>(new Set());

    const fetchTableData = useCallback(async () => {
        const { page, pageSize } = paginationModel;

        const filters: Record<string, string[]> = {};
        if (start)
            filters['filter.first_seen'] = [`<=${format(parseISO(end), 'yyyy-MM-dd')}T23:59:59`];
        if (end)
            filters['filter.last_seen'] = [`>=${format(parseISO(start), 'yyyy-MM-dd')}T00:00:00`];
        if (logImportSources.length > 0) filters['filter.logImportSource.id'] = logImportSources;

        const searchPayload = {
            filters,
            page: page + 1,
            perPage: pageSize,
            order: 'id',
            includes: ['logImportSource'],
            searchables: generateSearchColumnsData([selectedSearchField], theSearchTerm, false),
        };

        const [err, result] = await to(fetchMedia(searchPayload));
        if (result) {
            const newRows = result.data.data || [];
            setRows(newRows);
            setTotalRowCount(result.data.meta.pagination.total || 0);

            setSelectionModel(prevSelectionModel => {
                const newSelection = new Set(prevSelectionModel);
                newRows.forEach(row => {
                    if (!deselectedModel.has(row.id)) {
                        const match = mediaTableData.find(m => m.id === row.id);
                        if (match) {
                            const hasIdParameter = match.parameter.some(p => p.parameter === 'id');
                            const hasOtherParameter = match.parameter.some(
                                p => p.parameter !== 'id',
                            );

                            if (hasIdParameter) {
                                newSelection.add(row.id);
                            }
                        }
                    }
                });
                return newSelection;
            });

            setIntermediateModel(prevIntermediateModel => {
                const newIntermediate = new Set(prevIntermediateModel);
                newRows.forEach(row => {
                    if (!selectionModel.has(row.id) && !deselectedModel.has(row.id)) {
                        const match = mediaTableData.find(m => m.id === row.id);
                        if (match) {
                            const hasIdParameter = match.parameter.some(p => p.parameter === 'id');
                            const hasOtherParameter = match.parameter.some(
                                p => p.parameter !== 'id',
                            );
                            if (!hasIdParameter && hasOtherParameter) {
                                newIntermediate.add(row.id);
                            }
                        }
                    }
                });
                return newIntermediate;
            });
        }
    }, [
        paginationModel,
        start,
        end,
        logImportSources,
        selectedSearchField,
        theSearchTerm,
        fetchMedia,
        mediaTableData,
        selectionModel,
        deselectedModel,
    ]);

    useEffect(() => {
        if (logImportSources.length > 0 && start && end) {
            if (shouldFetch.value) {
                fetchTableData();
                shouldFetch.setFalse();
            }
        }
    }, [fetchTableData, logImportSources.length, start, end, shouldFetch]);

    const handleCheckboxChange = useCallback(
        (id: number) => {
            const newSelection = new Set(selectionModel);
            const newIntermediate = new Set(intermediateModel);
            const newDeselected = new Set(deselectedModel);

            const mediaItem = mediaTableData.find(m => m.id === id);
            const hasOtherParameter = mediaItem?.parameter.some(p => p.parameter !== 'id');

            if (selectionModel.has(id)) {
                newSelection.delete(id);
                if (hasOtherParameter && !deselectedModel.has(id)) {
                    newIntermediate.add(id);
                } else {
                    newDeselected.add(id);
                }
            } else if (intermediateModel.has(id)) {
                newSelection.add(id);
                newIntermediate.delete(id);
                newDeselected.delete(id);
            } else if (deselectedModel.has(id)) {
                newSelection.add(id);
                newDeselected.delete(id);
            } else {
                newSelection.add(id);
            }

            setSelectionModel(newSelection);
            setIntermediateModel(newIntermediate);
            setDeselectedModel(newDeselected);
        },
        [selectionModel, intermediateModel, deselectedModel, mediaTableData],
    );

    const handleApplyChanges = useCallback(async () => {
        const allSelectedIds = new Set(selectionModel);
        const allDeselectedIds = new Set(deselectedModel);

        const addPayload = Array.from(allSelectedIds).filter(id => {
            const mediaItem = mediaTableData.find(m => m.id === id);
            if (!mediaItem) return true;
            return !mediaItem.parameter?.some(
                p => p?.parameter === 'id' && p?.identifier === String(id),
            );
        });

        const removePayload = mediaTableData
            .filter(m => {
                const isArray = Array.isArray(m.parameter);
                return (
                    isArray &&
                    m.parameter.some(
                        p =>
                            p?.parameter === 'id' &&
                            (allDeselectedIds.has(m.id) || intermediateModel.has(m.id)),
                    )
                );
            })
            .map(m => m.parameter.find(p => p?.parameter === 'id')?.id)
            .filter(id => id !== undefined);

        if (addPayload.length > 0) {
            await updateParams(campaignId, {
                parameter: 'id',
                identifier: addPayload.map(String),
            });
        }
        if (removePayload.length > 0) {
            await deleteParams(campaignId, removePayload);
        }

        if (onApplyChanges) onApplyChanges();

        creativesDialogState.setFalse();
    }, [
        selectionModel,
        deselectedModel,
        intermediateModel,
        mediaTableData,
        creativesDialogState,
        onApplyChanges,
        updateParams,
        campaignId,
        deleteParams,
    ]);

    const cols: GridColDef[] = [
        {
            field: 'checkbox',
            headerName: '',
            width: 50,
            renderCell: ({ row }) => (
                <Checkbox
                    indeterminate={intermediateModel.has(row.id)}
                    checked={selectionModel.has(row.id)}
                    onChange={() => handleCheckboxChange(row.id)}
                />
            ),
        },
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'logImportSource.name', headerName: 'Log Import Source', flex: 1 },
        {
            field: 'creative_ref',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.creativeRef'),
            flex: 1,
        },
        {
            field: 'media_extid',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.mediaExtId'),
            flex: 1,
        },
        {
            field: 'campaign_name',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.campaignName'),
            flex: 1,
        },
        {
            field: 'campaign_extid',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.campaignExtId'),
            flex: 1,
        },
        {
            field: 'campaign_ref',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.campaignRef'),
            flex: 1,
        },
        {
            field: 'first_seen',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.firstSeen'),
            flex: 1,
        },
        {
            field: 'last_seen',
            headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.lastSeen'),
            flex: 1,
        },
    ];
    const handleLogImportSourcesChange = (value: string[]) => {
        setLogImportSources(value);
        shouldFetch.setTrue();
    };

    const handleDateRangeChange = (range: [string, string]) => {
        setDateRange(range);
        shouldFetch.setTrue();
    };

    const handleSearchColumnChange = (value: string) => {
        setSelectedSearchField(value);
        shouldFetch.setTrue();
    };

    const handleSearchTermChange = (value: string) => {
        setTheSearchTerm(value);
        shouldFetch.setTrue();
    };

    const handlePaginationChange = (newPaginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(newPaginationModel);
        shouldFetch.setTrue();
    };

    return (
        <>
            <Button onClick={creativesDialogState.setTrue}>
                {t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.searchCreatives')}
            </Button>
            <DialogV2
                open={creativesDialogState.value}
                onClose={creativesDialogState.setFalse}
                title="Media Selection"
                maxWidth="xl"
            >
                {error && <Alert severity="error">{String(error)}</Alert>}
                <AutoGrid xs={4} pt={2} gap={2}>
                    <EntityAutocomplete
                        entityName="PopDisplays_LogImportSource"
                        value={logImportSources}
                        labelColumn="name"
                        searchColumns={['name']}
                        onChange={handleLogImportSourcesChange}
                        multiple
                        textFieldProps={{ label: t('Log Import Source'), size: 'small' }}
                    />
                    <DateRangeField
                        onChange={handleDateRangeChange}
                        value={[start, end]}
                        label={t('Date Range')}
                        inputProps={{ size: 'small' }}
                    />
                    <SelectField
                        options={[
                            { label: 'Name', value: 'name' },
                            { label: 'Creative Reference', value: 'creative_ref' },
                            { label: 'Media External Id', value: 'media_extid' },
                            { label: 'Campaign Name', value: 'campaign_name' },
                            { label: 'Campaign External Id', value: 'campaign_extid' },
                            { label: 'Campaign Reference', value: 'campaign_ref' },
                            { label: 'External Source', value: 'ext_source' },
                            { label: 'Any', value: 'any' },
                        ]}
                        value={selectedSearchField}
                        size="small"
                        onChange={e => handleSearchColumnChange(e.target.value as string)}
                    />
                    <TextField
                        onChange={e => handleSearchTermChange(e.target.value)}
                        value={theSearchTerm}
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </AutoGrid>
                <DataGridPro
                    columns={cols}
                    rows={rows}
                    rowCount={totalRowCount}
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    loading={isLoading}
                />
                <AutoGrid justifyContent="end" p={2}>
                    <Button onClick={handleApplyChanges}>
                        {delLoading || updateLoading ? (
                            <CircularProgress />
                        ) : (
                            t('Modules.Main.Campaigns.CampaignSchedule.mediaTable.apply')
                        )}
                    </Button>
                    {delError && <Alert severity="error">{String(delError)}</Alert>}
                    {updateError && <Alert severity="error">{String(updateError)}</Alert>}
                </AutoGrid>
            </DialogV2>
        </>
    );
};

export default PopSettingsCreativesSearch;
