import React, { useMemo } from 'react';
import { ScheduleRuleGet } from '@uniled/api-sdk';
import { getTimeRanges, ScheduleDayToJSDayNumber } from 'c-main/Components/Schedule/lib';
import { useDateUtils } from 'c-hooks';
import { Stack, Typography } from '@mui/material';

import { useCommonTranslation } from 'c-translation';

type Props = {
    rule: ScheduleRuleGet;
};

const ScheduleRuleSummary: React.FC<Props> = ({ rule }) => {
    const { formatDateString } = useDateUtils();
    const dates = useMemo(
        () =>
            `${rule?.start_date ? formatDateString({ date: new Date(rule?.start_date) }) : ''} ${
                rule?.start_date ? ' - ' : ''
            }${
                rule?.end_date
                    ? formatDateString({
                          date: new Date(rule?.end_date),
                      })
                    : ''
            }`,
        [formatDateString, rule?.start_date, rule?.end_date],
    );
    const timePairs = useMemo(
        () => getTimeRanges(rule?.hours_of_day).join(', '),
        [rule?.hours_of_day],
    );
    const t = useCommonTranslation();
    const daysOfWeek = useMemo(
        () =>
            (rule?.days_of_week as any)
                .map(dayName => t(`WeekdaysShort.${ScheduleDayToJSDayNumber[dayName]}`))
                .join(', '),
        [rule?.days_of_week, t],
    );
    return (
        <Stack gap={0.5}>
            <Typography variant="caption">{dates}</Typography>
            <Typography variant="caption">{timePairs}</Typography>
            <Typography variant="caption">
                {rule?.sov ? `${t('Modules.Main.Campaigns.CampaignSchedule.summary.sov')}: ` : ''}{' '}
                {rule?.sov}
            </Typography>
            <Typography variant="caption">
                {rule?.slot_length
                    ? `${t('Modules.Main.Campaigns.CampaignSchedule.summary.slotLength')}: `
                    : ''}
                {rule?.slot_length}
            </Typography>
            <Typography variant="caption">{daysOfWeek}</Typography>
        </Stack>
    );
};

export default ScheduleRuleSummary;
