import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapDataType, ReportTabProps } from 'c-main/Components/Campaign/CampaignReports/types';
import { Box, CircularProgress, Collapse, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import { usePrevious } from 'react-hanger';
import { GoogleMap, HeatmapLayer, Marker, MarkerClusterer } from '@react-google-maps/api';
import {
    ReportConfig,
    ReportDateTimeframe,
    ReportWrapperProps,
    ReportWrapperRefAPI,
} from 'c-reports/Types';
import {
    CampaignNoticeKey,
    ElasticSearchAvailableFilters,
    ElasticSearchFrameIdMapDataResponse,
    PCAMapReportResponse,
    PCAReportCountField,
    PCAReportField,
} from '@uniled/api-sdk';
import { apiClient } from 'c-data';
import { format } from 'date-fns';
import { scrollToTopReportingTab } from 'c-main/Components/constants';
import { ReportWrapper } from 'c-reports/Components';
import {
    getCampaignReportingMapCenter,
    getCampaignReportingMapZoom,
    ReportingMapOptions,
} from 'c-main/Lib';
import { NetworkRequestState } from '@uniled/data-layer';
import { Controls, SelectedFrameInfo } from './Map';
import usePCAMapState from './usePCAMapState';

type Props = ReportTabProps & {
    additionalControls?: React.ReactNode;
    onEsFiltersUpdated: (filters: ElasticSearchAvailableFilters) => void;
};

const containerStyle = {
    width: '100%',
    height: '80vh',
};

const PCAMap: React.FC<Props> = ({
    campaign,
    metric,
    campaignStart,
    campaignEnd,
    currentStartDate,
    currentEndDate,
    onResponse,
    additionalControls,
    advancedFilter,
    showAdvancedFilters,
    fieldFilters,
    countFields,
    environment,
    onEsFiltersUpdated,
    dateSettings,
}) => {
    const {
        mapRef,
        onMapLoad,
        onMapUnmount,
        pcaData,
        setPcaData,
        selectedFrameMarker,
        selectedFrameInfo,
        dataTypes,
        toggleMapType,
        heatLayerSelected,
        heatMapData,
        markerLocations,
        onMarkerClick,
    } = usePCAMapState(metric);

    const theme = useTheme();
    const reportWrapperRef = useRef<ReportWrapperRefAPI>();

    const [loading, setLoading] = useState(null);
    const [accumulativeOvertime, setAccumulativeOvertime] = useState(false);

    const prevCountFields = usePrevious(countFields);
    const [hasFetchedMapData, setHasFetchedMapData] = useState(false);

    const center = useMemo(() => getCampaignReportingMapCenter(campaign), [campaign]);
    const zoom = useMemo(() => getCampaignReportingMapZoom(campaign), [campaign]);

    const scrollDownOnce = useRef(false);
    const prevData = usePrevious(pcaData);
    useEffect(() => {
        if (prevData == null && pcaData != null && !scrollDownOnce.current) {
            scrollDownOnce.current = true;
            scrollToTopReportingTab();
        }
    }, [pcaData, prevData]);

    const fetchData: ReportConfig<PCAMapReportResponse>['fetchData'] = useCallback(
        (start, end, timeframe) =>
            apiClient.ReportsElasticSearch.frameIdMapData({
                campaignId: campaign.id,
                environment,
                fieldFilters,
                dataStatus: fieldFilters?.[PCAReportField.DataStatus] as CampaignNoticeKey[],
                showFilters: showAdvancedFilters,
                startDate:
                    timeframe !== ReportDateTimeframe.All
                        ? `${format(start, 'yyyy-MM-dd')} 00:00:00`
                        : undefined,
                endDate:
                    timeframe !== ReportDateTimeframe.All
                        ? `${format(end, 'yyyy-MM-dd')} 23:59:59`
                        : undefined,
            }),
        [campaign.id, environment, fieldFilters, showAdvancedFilters],
    );

    const onDataUpdated: ReportConfig<any>['onDataUpdated'] = useCallback(
        (
            data: PCAMapReportResponse | ElasticSearchFrameIdMapDataResponse,
            start,
            end,
            timeframe,
        ) => {
            setPcaData(data);

            onEsFiltersUpdated((data as ElasticSearchFrameIdMapDataResponse)?.filters);

            if (data) {
                onResponse(data, start, end, timeframe);
            }
        },
        [setPcaData, onResponse, onEsFiltersUpdated],
    );
    useEffect(() => {
        if (showAdvancedFilters && !hasFetchedMapData) {
            setHasFetchedMapData(true);
            reportWrapperRef.current?.refreshData?.();
        }
    }, [showAdvancedFilters, hasFetchedMapData]);

    useEffect(() => {
        // just to stop it fetching data on mount
        if (prevCountFields != undefined) {
            reportWrapperRef?.current?.refreshData?.();

            if (
                countFields.indexOf(PCAReportCountField.InSchedule) === -1 &&
                heatLayerSelected != null &&
                heatLayerSelected !== MapDataType.HeatActual
            ) {
                // in schedule not selected, so go back to actual plays heat layer if a heat layer is selected
                // because peformance/expected don't make sense if in schedule isn't selected
                toggleMapType(MapDataType.HeatActual);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countFields, fieldFilters]);

    const reportWrapperProps = useMemo<ReportWrapperProps<any>>(
        () => ({
            fetchData,
            onDataUpdated,
            initialStartDate: currentStartDate,
            initialEndDate: currentEndDate,
            onLoadingStateUpdated: setLoading,
            reportChartWrapperProps: { height: 500 },
            dateSettings,
        }),
        [fetchData, onDataUpdated, currentStartDate, currentEndDate, dateSettings],
    );

    const heatmapLayer = useMemo(() => <HeatmapLayer data={heatMapData} />, [heatMapData]);
    const markers = useMemo(
        () => (
            <MarkerClusterer maxZoom={14} ignoreHidden>
                {markerClusterer => (
                    <>
                        {markerLocations.map(({ frameId, location, hidden }, idx) => (
                            <Marker
                                key={`${frameId}-${location.lat()}-${location.lng()}`}
                                position={location}
                                visible={!hidden}
                                clusterer={markerClusterer}
                                onClick={() => onMarkerClick(idx)}
                                icon={{
                                    path: 'M3 5h18v12H3z, M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z',
                                    fillColor: theme.palette.primary.main,
                                    strokeColor: theme.palette.primary.contrastText,
                                    fillOpacity: 1,
                                    scale: selectedFrameMarker === frameId ? 1.5 : 1,
                                    anchor: new google.maps.Point(12.5, 25),
                                }}
                            />
                        ))}
                    </>
                )}
            </MarkerClusterer>
        ),
        [markerLocations, onMarkerClick, theme, selectedFrameMarker],
    );

    // can place overlay view inside a marker to customise the marker.
    // https://github.com/JustFly1984/react-google-maps-api/issues/846
    // https://codesandbox.io/s/inspiring-fast-wou8k?file=/src/App.tsx
    // https://github.com/mapbox/supercluster
    // {/*    <OverlayView mapPaneName="markerLayer" position={location}>*/}
    // {/*        <Box height={20} width={20} bgcolor="red" />*/}
    // {/*    </OverlayView>*/}
    // {/*</Marker>*/}

    const showMarkers = useMemo(() => dataTypes.indexOf(MapDataType.Marker) !== -1, [dataTypes]);
    const reportProps = useMemo(() => [], []);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReportWrapper
                        ref={reportWrapperRef}
                        reportProps={reportProps}
                        {...reportWrapperProps}
                        requestKeyPrefix={countFields.join('')}
                        reportWrapperProps={{ height: 0, minHeight: 0 }}
                        beforeChartComponent={
                            advancedFilter && (
                                <Collapse in={showAdvancedFilters}>
                                    <Box mb={2}>{advancedFilter}</Box>
                                </Collapse>
                            )
                        }
                        additionalDateControls={additionalControls}
                        additionalDateControlsPosition="end"
                        afterChartComponent={
                            <Box id={`${metric}_heatmap`} sx={{ position: 'relative' }}>
                                {loading?.state === NetworkRequestState.InProgress && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            zIndex: 10,
                                        }}
                                    >
                                        <CircularProgress size={50} color="primary" />
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        top: 20,
                                        left: 20,
                                    }}
                                >
                                    <Controls
                                        countFields={countFields}
                                        toggleMapType={toggleMapType}
                                        dataTypes={dataTypes}
                                        metric={metric}
                                        heatLayerSelected={heatLayerSelected}
                                    />
                                </Box>
                                <GoogleMap
                                    key={heatLayerSelected}
                                    ref={mapRef as any}
                                    onLoad={onMapLoad}
                                    onUnmount={onMapUnmount}
                                    zoom={zoom}
                                    center={center}
                                    mapContainerStyle={containerStyle}
                                    options={ReportingMapOptions}
                                >
                                    {heatLayerSelected != null && heatmapLayer}
                                    {showMarkers && markers}
                                </GoogleMap>
                            </Box>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {selectedFrameInfo != null && (
                        <SelectedFrameInfo
                            key={selectedFrameMarker}
                            frameInfo={selectedFrameInfo}
                            frameId={selectedFrameMarker}
                            metric={metric}
                            campaign={campaign}
                            start={campaignStart}
                            end={campaignEnd}
                            accumulativeOverTime={accumulativeOvertime}
                            setAccumulativeOverTime={setAccumulativeOvertime}
                            environment={environment}
                            fieldFilters={fieldFilters}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default PCAMap;
