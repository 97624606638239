import React, { useCallback, useEffect, useMemo } from 'react';
import { PermissionName, ScheduleGet } from '@uniled/api-sdk';
import { Box, CircularProgress, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { cloudDownloadIcon, deleteIcon, editIcon, refreshIcon } from 'c-main/icons';
import { Alert, Chip } from 'c-components';
import {
    AddToQueue,
    CheckCircleOutlined,
    ExpandLess,
    ExpandMore,
    RemoveFromQueue,
    Tv,
    Warning,
    Error,
} from '@mui/icons-material';
import { scheduleName } from 'c-main/Components/Schedule/lib';
import useCampaignSchedule from 'c-main/Components/Schedule/useCampaignSchedule';
import SchedulableAssociateButtons from 'c-main/Components/Schedule/Components/Schedulables/SchedulableAssociateButtons';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { UseBoolean, useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { NetworkRequestState } from '@uniled/data-layer';
import ScheduleRuleSummary from './ScheduleRuleSummary';

type Props = {
    campaignId: number;
    schedule: ScheduleGet;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    onScreensClicked: () => void;
    onDetachClicked: () => void;
    setId: (id: number) => void;
    collapseAll?: UseBoolean;
};

const updatePerms = [
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesDestroy,
    PermissionName.UniliveapiRulesCreate,
    PermissionName.UniliveapiRulesUpdate,
    PermissionName.UniliveapiRulesDestroy,
];

const deletePerms = [
    PermissionName.UniliveapiSchedulesDestroy,
    PermissionName.UniliveapiRulesDestroy,
];

const attachPerms = [
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesRead,
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesDestroy,
];

const ScheduleListItem: React.FC<Props> = ({
    campaignId,
    schedule,
    onEdit,
    onDelete,
    onScreensClicked,
    onDetachClicked,
    setId,
    collapseAll,
}) => {
    // const timePairs = useMemo(() => ruleHoursToTimePairs(rule.hours_of_day), [rule.hours_of_day]);
    const { expandedScheduleId, setExpandedScheduleId } = useNewCampaignSchedule(campaignId);
    const collapsed = useBoolean(false);
    const focusRef = React.useRef<HTMLDivElement>(null);
    const expanded = useMemo(
        () => expandedScheduleId === schedule.id,
        [expandedScheduleId, schedule.id],
    );
    const { getSchedules, scheduleSearch, schedulesPerPage, schedulesCurrentPage } =
        useNewCampaignSchedule(campaignId);

    useEffect(() => {
        if (expanded) {
            collapsed.setTrue();
            focusRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [collapsed, expanded, expandedScheduleId, schedule.id]);

    useEffect(() => {
        if (collapseAll.value) {
            collapsed.setFalse();
            collapseAll.setFalse();
        }
    }, [collapseAll, collapsed]);

    const rules = useMemo(
        () => (
            <Stack>
                {schedule?.rules?.map(rule => (
                    <Box key={rule.id}>
                        <ScheduleRuleSummary rule={rule} />
                    </Box>
                ))}
            </Stack>
        ),
        [schedule.rules],
    );
    const { selected, selectedScreens } = useNewCampaignSchedule(campaignId);
    const allSelected = useMemo(
        () => selected.concat(selectedScreens),
        [selected, selectedScreens],
    );
    const onEditClicked = useCallback(() => {
        onEdit(schedule.id);
    }, [onEdit, schedule.id]);
    const onDeleteClicked = useCallback(() => {
        onDelete(schedule.id);
    }, [onDelete, schedule.id]);

    const t = useCommonTranslation();
    const selectedSchedulables = useCampaignSchedule(campaignId).selectedSchedulables;

    const header = useMemo(
        () => (
            <Typography
                variant="subtitle2"
                color="primary"
                sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
            >
                {scheduleName(schedule, t)}
            </Typography>
        ),
        [schedule, t],
    );
    const attachClick = useCallback(() => {
        setId(schedule.id);
        onScreensClicked();
    }, [onScreensClicked, schedule.id, setId]);

    const detachClick = useCallback(() => {
        setId(schedule.id);
        onDetachClicked();
    }, [onDetachClicked, schedule.id, setId]);
    const { start } = useAPIClientRequest(ApiClient.Entities.Schedule.downloadSite);

    const handleDownload = useCallback(async () => {
        const [error, response] = await to(start(campaignId, schedule.id));
        if (error) {
            console.error('Error during CSV download', error);
            return;
        }
        const csvData = response?.data;
        if (csvData) {
            const blob = new Blob([String(csvData)], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${schedule.name}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }, [campaignId, schedule.id, schedule.name, start]);
    const {
        start: revStart,
        data,
        error,
        requestState,
        isLoading,
    } = useAPIClientRequest(ApiClient.Entities.Schedule.revalidateSite);

    const handleRevalidate = useCallback(async () => {
        await to(revStart(campaignId, schedule.id));
    }, [campaignId, revStart, schedule.id]);

    useEffect(() => {
        if (requestState === NetworkRequestState.Success) {
            getSchedules(schedulesCurrentPage, schedulesPerPage, scheduleSearch);
        }
        // eslint-disable-next-line
    }, [requestState]);

    const actions = useMemo(
        () => (
            <Box>
                <IfHasAllPermissions permissions={attachPerms}>
                    {!schedule.is_file && (
                        <IconButton
                            color="primary"
                            disabled={allSelected.length == 0}
                            onClick={attachClick}
                            size="small"
                        >
                            <AddToQueue fontSize="inherit" />
                        </IconButton>
                    )}
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={attachPerms}>
                    {!schedule.is_file && (
                        <IconButton
                            color="primary"
                            disabled={allSelected.length == 0}
                            onClick={detachClick}
                            size="small"
                        >
                            <RemoveFromQueue fontSize="inherit" />
                        </IconButton>
                    )}
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={updatePerms}>
                    {!schedule.is_file && (
                        <IconButton
                            onClick={onEditClicked}
                            disabled={schedule.is_default || schedule.disable_flag}
                            size="small"
                        >
                            {editIcon}
                        </IconButton>
                    )}
                </IfHasAllPermissions>
                {schedule.is_file && !isLoading && !schedule.scheduleFile.data.refresh && (
                    <IconButton onClick={handleRevalidate}>{refreshIcon}</IconButton>
                )}
                {schedule.is_file && isLoading && <CircularProgress />}
                <IfHasAllPermissions permissions={deletePerms}>
                    <IconButton
                        color="error"
                        onClick={onDeleteClicked}
                        size="small"
                        disabled={schedule.is_default || schedule.disable_flag}
                    >
                        {deleteIcon}
                    </IconButton>
                </IfHasAllPermissions>
                {schedule.is_file && (
                    <IconButton color="primary" onClick={handleDownload}>
                        {cloudDownloadIcon}
                    </IconButton>
                )}
                {!schedule.is_file && (
                    <IconButton onClick={collapsed.toggle} size="small">
                        {collapsed.value ? (
                            <ExpandLess
                                fontSize="inherit"
                                onClick={() => setExpandedScheduleId(null)}
                            />
                        ) : (
                            <ExpandMore fontSize="inherit" />
                        )}
                    </IconButton>
                )}
            </Box>
        ),
        [
            allSelected.length,
            attachClick,
            collapsed.toggle,
            collapsed.value,
            detachClick,
            handleDownload,
            handleRevalidate,
            isLoading,
            onDeleteClicked,
            onEditClicked,
            schedule,
            setExpandedScheduleId,
        ],
    );
    const screenCount = useMemo(
        () =>
            schedule?.schedulables?.length > 0 && (
                <>
                    <Chip
                        variant="outlined"
                        size="small"
                        icon={<Tv fontSize="inherit" sx={{ pl: 0.5 }} />}
                        label={schedule.schedulables.length}
                    />
                </>
            ),
        [schedule?.schedulables?.length],
    );

    return (
        <Box ref={focusRef}>
            <Stack gap={1} alignItems="center" direction="row" justifyContent="space-between">
                {header}
                {selectedSchedulables.length > 0 ? (
                    <SchedulableAssociateButtons campaignId={campaignId} schedule={schedule} />
                ) : (
                    actions
                )}
            </Stack>
            {!schedule.is_file && (
                <Collapse in={collapsed.value}>
                    {rules}
                    {screenCount}
                </Collapse>
            )}
            {schedule.is_file && (
                <Box sx={{ marginTop: '-1rem' }}>
                    <Typography variant="inherit" fontWeight="-moz-initial" fontSize="small">
                        <Stack gap={1}>
                            <Stack direction="row" gap={1} pt={1}>
                                {schedule.scheduleFile.data.status}
                                {schedule.scheduleFile.data.status === 'Success' && (
                                    <CheckCircleOutlined color="success" />
                                )}
                                {schedule.scheduleFile.data.status === 'Warning' && (
                                    <Warning color="warning" />
                                )}
                                {schedule.scheduleFile.data.status === 'Error' && (
                                    <Error color="error" />
                                )}
                            </Stack>

                            {error && <Alert severity="error">{String(error)}</Alert>}
                        </Stack>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ScheduleListItem;
