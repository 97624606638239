import {
    CampaignStats,
    ElasticSearchFrameIdData,
    ElasticSearchFrameIdMapDataResponse,
    ElasticSearchGroupByFieldResponse,
    ElasticSearchPerformanceStats,
    ElasticSearchSchedule,
    EsPCAReportField,
    EsPCAReportFilterField,
    PCAFrameDetails,
    PCAReportByFieldPlayStats,
    PCAReportByFieldResponse,
    PCAReportCountField,
    PCAReportMetric,
    PCAReportPlayStats,
} from '@uniled/api-sdk';

export const performanceStatsToOldCampaignStats = (
    data: ElasticSearchPerformanceStats,
): CampaignStats => ({
    plays: data?.sum_of_plays ?? 0,
    plays_expected: data?.expected_plays_in_schedule ?? 0,
    plays_percentage: data
        ? getPerformance(data?.expected_plays_in_schedule, data?.sum_of_plays)
        : '0',
    plays_in_schedule: data?.plays_received_in_schedule ?? 0,
    plays_out_schedule: data?.plays_received_out_of_schedule ?? 0,
    plays_unbooked: data?.plays_received_unbooked ?? 0,

    impacts: data?.impacts ?? 0,
    impacts_expected: data?.expected_impacts_in_schedule ?? 0,
    impacts_percentage: data
        ? getPerformance(data?.expected_impacts_in_schedule, data?.impacts)
        : '0',
    impacts_in_schedule: data?.impacts_received_in_schedule ?? 0,
    impacts_out_schedule: data?.impacts_received_out_of_schedule ?? 0,
    impacts_unbooked: data?.impacts_received_unbooked ?? 0,

    time: data?.sum_of_time ?? 0,
    time_expected: data?.expected_time_in_schedule ?? 0,
    time_percentage: data
        ? getPerformance(data?.expected_time_in_schedule, data?.sum_of_time)
        : '0',
    time_in_schedule: data?.time_received_in_schedule ?? 0,
    time_out_schedule: data?.time_received_out_of_schedule ?? 0,
    time_unbooked: data?.time_received_unbooked ?? 0,

    kilowatts: data ? String(data?.kilowatts) : '',
    kgco2: data ? String(data?.kgco2) : '',
});

export const groupedByFilterToLeaderboardData = ({
    metric,
    data,
    withResourceId = false,
    field,
}: {
    metric: PCAReportMetric;
    data: ElasticSearchGroupByFieldResponse;
    withResourceId?: boolean;
    field?: EsPCAReportField;
}): PCAReportByFieldResponse => {
    if (Object.keys(data?.data ?? {}).length > 0) {
        return {
            reportData: Object.entries(data.data).reduce(
                (acc, [fieldName, values]) => ({
                    ...acc,
                    [fieldName]:
                        withResourceId && field
                            ? esValuesToPcaValuesWithResourceId(metric, values, field)
                            : esValuesToPcaValues(metric, values),
                }),
                {},
            ),
        };
    }
    return { reportData: {} };
};

const keyMapping = {
    '0': 'display_id',
    '1': 'display_lat',
    '2': 'display_long',
    '3': 'sum_of_time',
    '4': 'sum_of_plays',
    '5': 'impacts',
};

export const mapDataToSqlMapData = (
    metric: PCAReportMetric,
    data: ElasticSearchFrameIdMapDataResponse,
) => {
    if (Object.keys(data?.data ?? {}).length > 0) {
        return {
            reportData: Object.entries(data.data).reduce(
                (acc, [frameId, values]) => ({
                    ...acc,
                    [frameId]: esFrameInfoToSqlMapPlayStats(
                        metric,
                        Object.entries(values).reduce((mappedValues, [key, value]) => {
                            const descriptiveKey = keyMapping[key] || key;
                            return { ...mappedValues, [descriptiveKey]: value };
                        }, {} as ElasticSearchFrameIdData),
                    ),
                }),
                {},
            ),
        };
    }
    return { reportData: {} };
};

export const esValuesToPcaValues = (metric: PCAReportMetric, stats): PCAReportPlayStats => {
    /**
     * when we need to make it return PCAReportByFieldPlayStats, add another separate function so it doesn't break
     * the map data transformer
     */
    if (metric === PCAReportMetric.Plays) {
        return [
            stats.sum_of_plays,
            stats.expected_plays_in_schedule,
            getPerformance(stats.expected_plays_in_schedule, stats.sum_of_plays),
            stats.plays_received_in_schedule,
            stats.plays_received_out_of_schedule,
            stats.plays_received_unbooked,
        ];
    }
    if (metric === PCAReportMetric.Impacts) {
        return [
            stats.impacts,
            stats.expected_impacts_in_schedule,
            getPerformance(stats.expected_impacts_in_schedule, stats.impacts),
            stats.impacts_received_in_schedule,
            stats.impacts_received_out_of_schedule,
            stats.impacts_received_unbooked,
        ];
    }

    return [
        stats.sum_of_time,
        stats.expected_time_in_schedule,
        getPerformance(stats.expected_time_in_schedule, stats.sum_of_time),
        stats.time_received_in_schedule,
        stats.time_received_out_of_schedule,
        stats.time_received_unbooked,
    ];
};
const esValuesToPcaValuesWithResourceId = (
    metric: PCAReportMetric,
    stats: ElasticSearchPerformanceStats,
    field: EsPCAReportField,
): PCAReportByFieldPlayStats => {
    let resId = null;
    if (field === EsPCAReportField.DisplayLineItemName) resId = stats.display_line_item_id;

    // disabling data status flag setting on media owners by never providing their ids
    // if (field === EsPCAReportField.DisplayOwner) resId = stats.display_owner_id;

    return [...esValuesToPcaValues(metric, stats), resId];
};

const esFrameInfoToSqlMapPlayStats = (metric: PCAReportMetric, data: ElasticSearchFrameIdData) => [
    ...esValuesToPcaValues(metric, data),

    data.display_lat,
    data.display_long,

    data.display_town,
    data.display_tv_region,
    data.display_surface_area,
    data.display_conurbation,
    data.display_env,
    data.display_format,
    data.display_line_item_name,
    data.display_owner,
    data.display_resolution,
    data.display_id,
];

const getPerformance = (expected: number, total: number) => {
    if (expected <= 0) {
        return '0';
    }
    const performance = (Math.round(total) / Math.round(expected)) * 100;
    return performance <= 0 ? '0' : String(performance);
};

export const getEsCountFields = (
    fieldFilters?: Record<string, string[]>,
): PCAReportCountField[] => {
    const currentCounts = fieldFilters?.[EsPCAReportFilterField.Schedule];

    if (Array.isArray(currentCounts) && currentCounts?.length > 0) {
        const fields: PCAReportCountField[] = [];

        if (currentCounts.indexOf(ElasticSearchSchedule.In) !== -1)
            fields.push(PCAReportCountField.InSchedule);
        if (currentCounts.indexOf(ElasticSearchSchedule.Out) !== -1)
            fields.push(PCAReportCountField.OutSchedule);
        if (currentCounts.indexOf(ElasticSearchSchedule.Unbooked) !== -1)
            fields.push(PCAReportCountField.UnBooked);

        return fields;
    }

    return [
        PCAReportCountField.InSchedule,
        PCAReportCountField.OutSchedule,
        PCAReportCountField.UnBooked,
    ];
};

export const esFrameDetailsToMySql = (
    frameId: string,
    data: ElasticSearchFrameIdData,
    metric: PCAReportMetric,
): PCAFrameDetails => ({
    latitude: data.display_lat,
    longitude: data.display_long,

    town: data.display_town,
    tvRegion: data.display_tv_region,
    surfaceArea: data.display_surface_area,
    conurbation: data.display_conurbation,
    environment: data.display_env,
    format: data.display_format,
    lineItemName: data.display_line_item_name,
    mediaOwner: data.display_owner,
    resolution: data.display_resolution,

    address: data.display_screen_name_summary,
    postcode: '',
    frameId,

    ...frameDetailPerformance(data, metric),
});

const frameDetailPerformance = (
    data: ElasticSearchFrameIdData,
    metric: PCAReportMetric,
): Pick<
    PCAFrameDetails,
    | 'actual'
    | 'expected'
    | 'percentage'
    | 'in_schedule'
    | 'out_schedule'
    | 'unbooked'
    | 'unbookedBool'
> => {
    if (metric === PCAReportMetric.Plays) {
        return {
            actual: data.sum_of_plays,
            expected: data.expected_plays_in_schedule,
            percentage: getPerformance(data.expected_plays_in_schedule, data.sum_of_plays),
            in_schedule: data.plays_received_in_schedule,
            out_schedule: data.plays_received_out_of_schedule,
            unbooked: data.plays_received_unbooked,
            unbookedBool: data.plays_received_unbooked > 0,
        };
    }
    if (metric === PCAReportMetric.Impacts) {
        return {
            actual: data.impacts,
            expected: data.expected_impacts_in_schedule,
            percentage: getPerformance(data.expected_impacts_in_schedule, data.impacts),
            in_schedule: data.impacts_received_in_schedule,
            out_schedule: data.impacts_received_out_of_schedule,
            unbooked: data.impacts_received_unbooked,
            unbookedBool: data.plays_received_unbooked > 0,
        };
    }

    return {
        actual: data.sum_of_time,
        expected: data.expected_time_in_schedule,
        percentage: getPerformance(data.expected_time_in_schedule, data.sum_of_time),
        in_schedule: data.time_received_in_schedule,
        out_schedule: data.time_received_out_of_schedule,
        unbooked: data.time_received_unbooked,
        unbookedBool: data.time_received_unbooked > 0,
    };
};
