import React from 'react';
import { Box } from '@mui/material';
import { AllEntitiesDropdown, OptionSchema } from 'c-components';
import { useCommonTranslation } from 'c-translation';

type Props = {
    onChange: (skinId: number) => void;
    selectedSkinId: number;
    additionalOptions: OptionSchema[];
    disabled?: boolean;
};

const AdminSkinSwitcher: React.FC<Props> = ({
    onChange,
    selectedSkinId,
    additionalOptions,
    disabled = false,
}) => (
    <Box py={2}>
        <AllEntitiesDropdown
            entityName="Skin"
            labelField="name"
            disabled={disabled}
            label={useCommonTranslation('Modules.Admin.SkinSwitcher.dropdownLabel')}
            value={selectedSkinId}
            onChange={onChange as any}
            additionalOptions={additionalOptions}
        />
    </Box>
);

export default AdminSkinSwitcher;
