import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, CircularProgress, Box } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { MetaLayout, usePostAuthHome } from 'c-wrapper';
import { NetworkRequestState } from '@uniled/data-layer';

import { authSelectors, authThunks } from 'c-auth-module/Slices';
import { useIsMobileView } from 'c-hooks';

const RevalidatePage: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { token } = useParams<{ token: string }>();
    const postAuthHome = usePostAuthHome();
    const $user = useSelector(authSelectors.authenticatedUser);
    const isMobile = useIsMobileView();

    const { revalidateState = { state: NetworkRequestState.Idle, error: null } } = useSelector(
        (state: any) => state.auth || {},
    );

    const { state: validationState, error } = revalidateState;

    useEffect(() => {
        if (token) {
            dispatch(authThunks.revalidateAccount(token, isMobile));
        } else {
            console.error('Invalid verification link.');
        }
    }, [dispatch, isMobile, location.search, token]);

    useEffect(() => {
        if ($user) {
            history.push(postAuthHome);
        }
    }, [$user, history, postAuthHome]);

    return (
        <MetaLayout metaTitle="Pages.Revalidate.metaTitle">
            <Box sx={{ textAlign: 'center', paddingTop: 4 }}>
                {validationState === NetworkRequestState.InProgress ? (
                    <CircularProgress />
                ) : (
                    validationState === NetworkRequestState.Error && (
                        <Alert severity="error">
                            {error === 'Error'
                                ? 'Invalid Validation Token'
                                : error === 'Invalid  Verification Code'
                                ? 'Invalid Validation Token'
                                : error ===
                                  'No query results for model [App\\Models\\Portal\\User].'
                                ? 'Invalid Validation Token'
                                : error}
                        </Alert>
                    )
                )}
            </Box>
        </MetaLayout>
    );
};

export default RevalidatePage;
