import React, { useCallback, useEffect, useState } from 'react';

import { Button, DialogV2, TextField } from 'c-components';
import { useBoolean } from 'react-hanger';
import { Box, InputAdornment, Stack } from '@mui/material';
import TargetPanel from 'c-main/Components/Schedule/Components/Targets/TargetPanel';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import CreateNewTarget from 'c-main/Components/Schedule/Components/Targets/CreateNewTarget';
import { useCommonTranslation } from 'c-translation';
import { Search } from '@mui/icons-material';
import { debounce } from 'lodash';

const Targets = ({ id }) => {
    const createBool = useBoolean(false);
    const { targets } = useNewCampaignSchedule(id);
    const t = useCommonTranslation();
    const [search, setSearch] = useState('');
    const { listTargets, isLoadingTargets, targetsCurrentPage, targetsPerPage } =
        useNewCampaignSchedule(id);
    const onSearchChange = useCallback(e => {
        setSearch(e.target.value);
    }, []);
    useEffect(() => {
        const debouncedApiCall = debounce(() => {
            listTargets(targetsCurrentPage, targetsPerPage, search);
        }, 1000);

        debouncedApiCall();

        return () => {
            debouncedApiCall.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    return (
        <Box>
            <DialogV2
                onClose={createBool.setFalse}
                open={createBool.value}
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.createTarget',
                )}
            >
                <CreateNewTarget id={id} createBool={createBool} />
            </DialogV2>
            <Stack gap={1}>
                <Button size="small" variant="text" onClick={createBool.setTrue}>
                    {t('Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.createTarget')}
                </Button>
                <Box pb={2}>
                    <TextField
                        value={search}
                        size="small"
                        variant="outlined"
                        onChange={onSearchChange}
                        placeholder={t(
                            'Modules.Main.Campaigns.CampaignSchedule.filters.searchLabel',
                        )}
                        disabled={isLoadingTargets}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search fontSize="inherit" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {targets.map(target => (
                    <Box key={target.id}>
                        <TargetPanel target={target} id={id} />
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};
export default Targets;
