import React, { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/styles';
import { sort } from 'ramda';
import { ReferenceLine } from 'recharts';
import { PCAReportCountField, PCAReportMetric } from '@uniled/api-sdk';
import { Grid, GridProps, Typography } from '@mui/material';
import { ChartReportPart, PartType, ReportType } from 'c-reports/Types';
import { ReportWrapper } from 'c-reports/Components';
import { Translate } from 'c-translation';
import { useDateUtils } from 'c-hooks';
import { ByFieldChartProps } from 'c-main/Components/Campaign/CampaignReports/types';
import { ByFieldOverall } from '../byFieldDataMutators';
import { sortByNameCustomField } from './sorting';

type Props = ByFieldChartProps;

// const small: GridProps = { xs: 12, lg: 6 };
const large: GridProps = { xs: 12 };
const sizes = [large, large];

const OverallCharts: React.FC<Props> = ({
    metric,
    pcaData,
    actualSelectedFieldParts,
    fieldLabel,
    expectedLabel,
    percentageLabel,
    actualLabel,
    missingLabel,
    inScheduleLabel,
    outScheduleLabel,
    unbookedLabel,
    renderTooltipLabel,
    reportWrapperProps,
    countFields,
    renderTooltipLabelHideActualValue,
    campaignId,
    field,
}) => {
    const theme = useTheme();
    const { dayMonthYearFormat } = useDateUtils();

    const isGroupedByCreativeName = fieldLabel === 'Creative Name';

    const data = useMemo(() => {
        let processedData = ByFieldOverall(
            pcaData,
            actualSelectedFieldParts,
            actualLabel,
            percentageLabel,
            expectedLabel,
            missingLabel,
            fieldLabel,
            inScheduleLabel,
            outScheduleLabel,
            unbookedLabel,
        );
        if (isGroupedByCreativeName) {
            processedData = processedData.filter(item => item[fieldLabel] !== '');
        }

        return sortByNameCustomField(processedData, fieldLabel, field, dayMonthYearFormat);
    }, [
        pcaData,
        actualSelectedFieldParts,
        actualLabel,
        percentageLabel,
        expectedLabel,
        missingLabel,
        fieldLabel,
        inScheduleLabel,
        outScheduleLabel,
        unbookedLabel,
        field,
        dayMonthYearFormat,
        isGroupedByCreativeName,
    ]);

    const PlaysVsExpected = useMemo<ChartReportPart[]>(() => {
        if (isGroupedByCreativeName) {
            return [
                {
                    dataKey: actualLabel,
                    barProps: { fill: theme.palette.primary.main },
                },
            ];
        }
        const bars = [] as ChartReportPart[];
        if (countFields.indexOf(PCAReportCountField.InSchedule) !== -1) {
            bars.push({
                dataKey: expectedLabel,
                barProps: { fill: theme.palette.grey['500'] },
            });
        }
        bars.push({
            dataKey: actualLabel,
            barProps: { fill: theme.palette.primary.main },
        });
        return bars;
    }, [isGroupedByCreativeName, expectedLabel, actualLabel, theme.palette, countFields]);

    const Performance = useMemo<ChartReportPart[]>(
        () => [
            {
                dataKey: percentageLabel,
                barProps: { fill: theme.palette.primary.main },
                partType: PartType.Bar,
            },
        ],
        [percentageLabel, theme.palette.primary.main],
    );

    const titles = useMemo(() => {
        if (isGroupedByCreativeName) {
            return [
                <Translate
                    key="overallActual"
                    path={`Modules.Main.Campaigns.Overview.Reports.${metric}.overallTabTotalTabActualTitle`}
                />,
            ];
        }
        return [
            <Translate
                key="overallActual"
                path={`Modules.Main.Campaigns.Overview.Reports.${metric}.overallCompareExpectedVsActualTitle`}
            />,
            <Translate
                key="overallPerformance"
                path={`Modules.Main.Campaigns.Overview.Reports.${metric}.overallCompareByPerformanceTitle`}
            />,
        ];
    }, [metric, isGroupedByCreativeName]);

    const RenderCharts: Props['reportWrapperProps']['ReportRender'] = useCallback(
        ({ reports }) => (
            <Grid container spacing={2} sx={{ py: 2 }}>
                {reports.map((r, ri) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item key={ri} {...sizes[ri]}>
                        <Typography variant="h3" align="center">
                            {titles[ri]}
                        </Typography>
                        {r}
                    </Grid>
                ))}
            </Grid>
        ),
        [titles],
    );

    const performanceTicks = useMemo(() => {
        const highestPercentage =
            Math.ceil(
                data.reduce((highest, curr) => {
                    if (highest < Number(curr[percentageLabel])) {
                        return Number(curr[percentageLabel]);
                    }
                    return highest;
                }, 0) / 10,
            ) * 10;

        const tick = Math.ceil(highestPercentage / 5 / 10) * 10;

        let num = 0;
        const ticks = [0, 100];
        while (num < highestPercentage) {
            num += tick;
            ticks.push(num);
        }
        return sort((a, b) => a - b, ticks);
    }, [data, percentageLabel]);

    return (
        <ReportWrapper
            reportProps={[
                {
                    type: ReportType.Bar,
                    namePrefix: `${campaignId}_${metric}_${field}_overall`,
                    xAxisDataKey: fieldLabel,
                    parts: PlaysVsExpected,
                    data,
                    renderTooltipLabel,
                    yAxisTickFormatterAsTime: metric === PCAReportMetric.Time,
                },
                ...(isGroupedByCreativeName
                    ? []
                    : [
                          {
                              type: ReportType.Bar,
                              namePrefix: `${campaignId}_${metric}_${field}_overall_performance`,
                              xAxisDataKey: fieldLabel,
                              parts: Performance,
                              data,
                              renderTooltipLabel: renderTooltipLabelHideActualValue,
                              hideLegend: true,
                              yAxisProps: {
                                  ticks: performanceTicks,
                              },
                              chartChildren: [
                                  <ReferenceLine
                                      key="ref-line"
                                      y={100}
                                      strokeDasharray="10 7"
                                      stroke="#000"
                                  />,
                              ],
                          },
                      ]),
            ]}
            {...reportWrapperProps}
            ReportRender={RenderCharts}
        />
    );
};

export default OverallCharts;
