import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useCommonTranslation } from 'c-translation';

import Targets from 'c-main/Components/Schedule/Components/Targets/Targets';
import CustomFooter from 'c-main/Components/Schedule/Components/LineItems/CustomFooter';
import CampaignScheduleLineItemList from './Components/LineItems/CampaignScheduleLineItemList';
import ScheduleList from './Components/Schedule/ScheduleList';
import DeleteScheduleDialog from './Components/Schedule/DeleteScheduleDialog';
import useNewCampaignSchedule from './useNewCampaignSchedule';

type Props = {
    id: number;
};

const containerSx = { height: '100%' };
const leftColSx = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    overflow: 'hidden',
};

const rightColSx = { height: '100%', display: 'flex' };

const CampaignSchedule: React.FC<Props> = ({ id }) => {
    const {
        fetchData,
        schedules: schedulesNew,
        scheduleLineItems,
        loading,
        selected,
        toggleSelectItem,
        setSelected,
        updateSchedules,
        removeSchedules,
    } = useNewCampaignSchedule(id);
    const [value, setValue] = useState('1');
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [id]);

    const [deleteId, setDeleteId] = useState<number>(null);
    const resetDelete = useCallback(() => {
        setDeleteId(null);
    }, []);
    const deleteSchedule = useMemo(
        () => (deleteId != null ? schedulesNew.find(s => s.id === deleteId) : null),
        [schedulesNew, deleteId],
    );

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const t = useCommonTranslation();

    return (
        <>
            {deleteSchedule != null && (
                <DeleteScheduleDialog
                    campaignId={id}
                    scheduleId={deleteId}
                    schedule={deleteSchedule}
                    onClose={resetDelete}
                    removeSchedules={removeSchedules}
                />
            )}
            <Grid container spacing={2} sx={containerSx} alignItems="stretch">
                <Grid item xs={12} md={6} lg={8} xl={9} sx={leftColSx}>
                    <CampaignScheduleLineItemList
                        campaignId={id}
                        schedules={schedulesNew}
                        lineItems={scheduleLineItems}
                        loading={loading}
                        setSelected={setSelected}
                        onSelectLineItems={toggleSelectItem}
                        selectedLineItems={selected}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3} sx={rightColSx}>
                    <Box sx={{ width: '100%', typography: 'body1', overflow: 'scroll' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={handleChange}
                                    aria-label="Campaign Schedule Tabs"
                                >
                                    <Tab
                                        label={t(
                                            'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.schedule',
                                        )}
                                        value="1"
                                    />
                                    <Tab
                                        label={t(
                                            'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.tabTitle',
                                        )}
                                        value="2"
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {schedulesNew.length > 0 && (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <Box sx={{ flex: 1, overflow: 'auto' }}>
                                            <ScheduleList
                                                id={id}
                                                onDelete={setDeleteId}
                                                updateSchedules={updateSchedules}
                                                fetchData={fetchData}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'sticky',
                                                bottom: 0,
                                                backgroundColor: 'white',
                                                zIndex: 1,
                                            }}
                                        >
                                            <CustomFooter
                                                campaignId={id}
                                                paginationType="schedules"
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </TabPanel>
                            <TabPanel value="2">
                                <Targets id={id} />
                                <Box
                                    sx={{
                                        position: 'sticky',
                                        bottom: 0,
                                        backgroundColor: 'white',
                                        zIndex: 1,
                                    }}
                                >
                                    <CustomFooter campaignId={id} paginationType="targets" />
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default CampaignSchedule;
