import React, { useMemo } from 'react';
import { TabWrapperSettings } from 'c-types';
import { TabsWrapper } from 'c-components';
import { Box, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useTheme } from '@mui/styles';

interface ReportingTableProps {
    type: 'audited' | 'nonAudited';
}
const nonAuditedFirstTable = [
    {
        combinedKey: 'JCDecaux | Airport | D02',
        'TTD Plays': '-',
        'VIOOH Plays': '34,625',
        'Sum of TTD Impressions': '-',
        'Sum of VIOOH Impressions': '124,590',
    },
    {
        combinedKey: 'JCDecaux | Airport | D04',
        'TTD Plays': '-',
        'VIOOH Plays': '12,181',
        'Sum of TTD Impressions': '-',
        'Sum of VIOOH Impressions': '125,203',
    },
    {
        combinedKey: 'JCDecaux | Airport | D05',
        'TTD Plays': '523',
        'VIOOH Plays': '2,999',
        'Sum of TTD Impressions': '224,273',
        'Sum of VIOOH Impressions': '7,748',
    },
    {
        combinedKey: 'JCDecaux | Airport | D6',
        'TTD Plays': '23,853',
        'VIOOH Plays': '128,107',
        'Sum of TTD Impressions': '1,161,979',
        'Sum of VIOOH Impressions': '1,415,710',
    },
    {
        combinedKey: 'JCDecaux | Rail | D6',
        'TTD Plays': '1,338',
        'VIOOH Plays': '3,333',
        'Sum of TTD Impressions': '1,553',
        'Sum of VIOOH Impressions': '1,554',
    },
    {
        combinedKey: 'JCDecaux | Roadside | D6',
        'TTD Plays': '723',
        'VIOOH Plays': '703',
        'Sum of TTD Impressions': '6,073',
        'Sum of VIOOH Impressions': '5,939',
    },
    {
        combinedKey: 'Grand Total',
        'TTD Plays': '26,437',
        'VIOOH Plays': '181,948',
        'Sum of TTD Impressions': '1,393,877',
        'Sum of VIOOH Impressions': '1,680,744',
    },
];

const nonAuditedSecondTable = [
    {
        Environment: 'Airport',
        'TTD Plays': '24,376',
        'VIOOH Plays': '177,912',
        'Sum of TTD Impressions': '1,386,252',
        'Sum of VIOOH Impressions': '1,673,251',
    },
    {
        Environment: 'Rail',
        'TTD Plays': '1,338',
        'VIOOH Plays': '3,333',
        'Sum of TTD Impressions': '1,553',
        'Sum of VIOOH Impressions': '1,554',
    },
    {
        Environment: 'Roadside',
        'TTD Plays': '723',
        'VIOOH Plays': '703',
        'Sum of TTD Impressions': '6,073',
        'Sum of VIOOH Impressions': '5,939',
    },
    {
        Environment: 'Grand Total',
        'TTD Plays': '26,437',
        'VIOOH Plays': '181,948',
        'Sum of TTD Impressions': '1,393,877',
        'Sum of VIOOH Impressions': '1,680,744',
    },
];

const auditedFirstTable = [
    {
        combinedKey: 'JCDecaux | Airport | D02',
        'TTD Plays': '800',
        'VIOOH Plays': '4,827',
        'Sum of TTD Impressions': '333,027',
        'Sum of VIOOH Impressions': '128,976',
        'Sum of Route Delivered Impacts': '12,561',
    },
    {
        combinedKey: 'JCDecaux | Airport | D6',
        'TTD Plays': '15,129',
        'VIOOH Plays': '32,509',
        'Sum of TTD Impressions': '140,640',
        'Sum of VIOOH Impressions': '33,980',
        'Sum of Route Delivered Impacts': '115,603',
    },
    {
        combinedKey: 'JCDecaux | Rail | D48',
        'TTD Plays': '72',
        'VIOOH Plays': '505',
        'Sum of TTD Impressions': '265,964',
        'Sum of VIOOH Impressions': '305,725',
        'Sum of Route Delivered Impacts': '152,194',
    },
    {
        combinedKey: 'JCDecaux | Rail | D6',
        'TTD Plays': '107,988',
        'VIOOH Plays': '154,176',
        'Sum of TTD Impressions': '1,420,778',
        'Sum of VIOOH Impressions': '1,399,978',
        'Sum of Route Delivered Impacts': '938,357',
    },
    {
        combinedKey: 'JCDecaux | Rail | TVZ',
        'TTD Plays': '1,751',
        'VIOOH Plays': '2,965',
        'Sum of TTD Impressions': '407,818',
        'Sum of VIOOH Impressions': '394,286',
        'Sum of Route Delivered Impacts': '178,431',
    },
    {
        combinedKey: 'JCDecaux | Roadside | D48',
        'TTD Plays': '114,601',
        'VIOOH Plays': '110,904',
        'Sum of TTD Impressions': '1,802,639',
        'Sum of VIOOH Impressions': '1,753,558',
        'Sum of Route Delivered Impacts': '1,486,529',
    },
    {
        combinedKey: 'JCDecaux | Roadside | D6',
        'TTD Plays': '459,492',
        'VIOOH Plays': '455,747',
        'Sum of TTD Impressions': '2,882,227',
        'Sum of VIOOH Impressions': '2,818,465',
        'Sum of Route Delivered Impacts': '2,341,556',
    },
    {
        combinedKey: 'JCDecaux | Roadside | DM6',
        'TTD Plays': '38,153',
        'VIOOH Plays': '47,748',
        'Sum of TTD Impressions': '765,258',
        'Sum of VIOOH Impressions': '758,420',
        'Sum of Route Delivered Impacts': '703,336',
    },
    {
        combinedKey: 'Grand Total',
        'TTD Plays': '737,986',
        'VIOOH Plays': '809,381',
        'Sum of TTD Impressions': '8,018,350',
        'Sum of VIOOH Impressions': '7,593,388',
        'Sum of Route Delivered Impacts': '5,928,567',
    },
];

const auditedSecondTable = [
    {
        Environment: 'Airport',
        'TTD Plays': '15,929',
        'VIOOH Plays': '37,336',
        'Sum of TTD Impressions': '473,667',
        'Sum of VIOOH Impressions': '162,956',
        'Sum of Route Delivered Impacts': '128,164',
    },
    {
        Environment: 'Rail',
        'TTD Plays': '109,811',
        'VIOOH Plays': '157,646',
        'Sum of TTD Impressions': '2,094,560',
        'Sum of VIOOH Impressions': '2,099,989',
        'Sum of Route Delivered Impacts': '1,268,983',
    },
    {
        Environment: 'Roadside',
        'TTD Plays': '612,246',
        'VIOOH Plays': '614,399',
        'Sum of TTD Impressions': '5,450,123',
        'Sum of VIOOH Impressions': '5,330,443',
        'Sum of Route Delivered Impacts': '4,531,420',
    },
    {
        Environment: 'Grand Total',
        'TTD Plays': '737,986',
        'VIOOH Plays': '809,381',
        'Sum of TTD Impressions': '8,018,350',
        'Sum of VIOOH Impressions': '7,593,388',
        'Sum of Route Delivered Impacts': '5,928,567',
    },
];

const headers = {
    auditedFirstTable: [
        'Modules.Main.Campaigns.Overview.dsp.combinedKeyCol',
        'Modules.Main.Campaigns.Overview.dsp.TTDPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.VIOOHPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.sumOfTTDImpressions',
        'Modules.Main.Campaigns.Overview.dsp.sumOfVioohImpressions',
        'Modules.Main.Campaigns.Overview.dsp.sumOfRouteDeliveredImpacts',
    ],
    auditedSecondTable: [
        'Modules.Main.Campaigns.Overview.dsp.environmentCol',
        'Modules.Main.Campaigns.Overview.dsp.TTDPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.VIOOHPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.sumOfTTDImpressions',
        'Modules.Main.Campaigns.Overview.dsp.sumOfVioohImpressions',
        'Modules.Main.Campaigns.Overview.dsp.sumOfRouteDeliveredImpacts',
    ],
    nonAuditedFirstTable: [
        'Modules.Main.Campaigns.Overview.dsp.combinedKeyCol',
        'Modules.Main.Campaigns.Overview.dsp.TTDPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.VIOOHPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.sumOfTTDImpressions',
        'Modules.Main.Campaigns.Overview.dsp.sumOfVioohImpressions',
    ],
    nonAuditedSecondTable: [
        'Modules.Main.Campaigns.Overview.dsp.environmentCol',
        'Modules.Main.Campaigns.Overview.dsp.TTDPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.VIOOHPlaysCol',
        'Modules.Main.Campaigns.Overview.dsp.sumOfTTDImpressions',
        'Modules.Main.Campaigns.Overview.dsp.sumOfVioohImpressions',
    ],
};

const ReportingTable: React.FC<ReportingTableProps> = ({ type }) => {
    const t = useCommonTranslation();
    const theme = useTheme();

    const tableData =
        type === 'audited'
            ? {
                  firstTable: auditedFirstTable,
                  secondTable: auditedSecondTable,
                  headersFirstTable: headers.auditedFirstTable,
                  headersSecondTable: headers.auditedSecondTable,
              }
            : {
                  firstTable: nonAuditedFirstTable,
                  secondTable: nonAuditedSecondTable,
                  headersFirstTable: headers.nonAuditedFirstTable,
                  headersSecondTable: headers.nonAuditedSecondTable,
              };

    const renderTable = (tableData, headers) => (
        <Table>
            <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                    {headers.map((header: string) => {
                        const padding =
                            header === 'Modules.Main.Campaigns.Overview.dsp.environmentCol';
                        const isLastThreeColumns =
                            header === 'Modules.Main.Campaigns.Overview.dsp.sumOfTTDImpressions' ||
                            header ===
                                'Modules.Main.Campaigns.Overview.dsp.sumOfVioohImpressions' ||
                            header ===
                                'Modules.Main.Campaigns.Overview.dsp.sumOfRouteDeliveredImpacts';

                        return (
                            <TableCell
                                key={header}
                                sx={{
                                    paddingRight: padding
                                        ? '15rem'
                                        : isLastThreeColumns
                                        ? '0rem'
                                        : '5rem',
                                }}
                            >
                                <Typography color={theme.palette.common.white}>
                                    {t(header)}
                                </Typography>
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map((row, rowIndex) => {
                    const isGrandTotal =
                        row.combinedKey === 'Grand Total' || row.Environment === 'Grand Total';

                    return (
                        <TableRow key={`${row.combinedKey || row.Environment}${+String(rowIndex)}`}>
                            {Object.values(row).map((value: string, cellIndex) => (
                                <TableCell
                                    key={`${value + String(cellIndex)}`}
                                    sx={{
                                        fontWeight: isGrandTotal ? 'bold' : null,
                                    }}
                                >
                                    {value}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );

    return (
        <Box>
            {renderTable(tableData.firstTable, tableData.headersFirstTable)}
            {renderTable(tableData.secondTable, tableData.headersSecondTable)}
        </Box>
    );
};
const UkDspReporting = () => {
    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.Main.Campaigns.Overview.dsp.audited',
                content: <ReportingTable type="audited" />,
                tabPanelStyling: { py: 0 },
                className: 'routeAuditedTable',
            },
            {
                title: 'Modules.Main.Campaigns.Overview.dsp.nonAudited',
                content: <ReportingTable type="nonAudited" />,
                tabPanelStyling: { py: 0 },
                className: 'nonRouteAuditedTable',
            },
        ],
        [],
    );

    return <TabsWrapper tabs={tabs} type="secondary" />;
};

export default UkDspReporting;
