import React, { useCallback, useMemo } from 'react';
import { Box, Collapse, IconButton, ListItemButton, Stack, Typography } from '@mui/material';
import {
    DateRangeField,
    ListItemText,
    OptionSchema,
    TextField,
    ToggleButtonGroup,
} from 'c-components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Translate, useCommonTranslation } from 'c-translation';
import { AddCircle, Delete } from '@mui/icons-material';
import { ScheduleFormData, ScheduleFormDataRuleHour } from 'c-main/Components/Schedule/types';
import { formRuleDataToRule, ScheduleDayToJSDayNumber } from 'c-main/Components/Schedule/lib';
import { useDeepCompareMemo } from 'use-deep-compare';
import { equals, isEmpty, isNil, reject } from 'ramda';
import { useDateUtils } from 'c-hooks';
import { DateRangePickerProps } from '@mui/x-date-pickers-pro';
import TimePickerFieldWith24Hours from 'c-components/Forms/TimePickerField/TimePickerFieldWith24Hours';

type Props = {
    index: number;
    remove: (index: number) => void;
    isOpen: boolean;
    onOpen: (index: number) => void;

    hideDates: boolean;
    dateRangeSlotProps: DateRangePickerProps<any>['slotProps'];
};

const RuleForm: React.FC<{ index: number } & Pick<Props, 'dateRangeSlotProps' | 'hideDates'>> = ({
    index,
    hideDates,
    dateRangeSlotProps,
}) => {
    const t = useCommonTranslation();

    const weekdayOpts = useMemo<OptionSchema[]>(
        () => [
            {
                label: t('WeekdaysShort.1'),
                value: 1,
            },
            {
                label: t('WeekdaysShort.2'),
                value: 2,
            },
            {
                label: t('WeekdaysShort.3'),
                value: 3,
            },
            {
                label: t('WeekdaysShort.4'),
                value: 4,
            },
            {
                label: t('WeekdaysShort.5'),
                value: 5,
            },
            {
                label: t('WeekdaysShort.6'),
                value: 6,
            },
            {
                label: t('WeekdaysShort.7'),
                value: 7,
            },
        ],
        [t],
    );

    const handleSovChange = (e, field) => {
        const value = e.target.value;
        field.onChange(value);
    };

    return (
        <Stack gap={2} mx={1}>
            {!hideDates && (
                <Controller
                    render={({ field }) => (
                        <DateRangeField
                            {...field}
                            label={t('Modules.Main.Campaigns.CampaignSchedule.Form.dateRangeLabel')}
                            inputProps={{
                                size: 'small',
                                variant: 'standard',
                            }}
                            slotProps={dateRangeSlotProps}
                        />
                    )}
                    name={`rules.${index}.date_range`}
                />
            )}

            <Stack gap={2}>
                <RuleHours index={index} />
                <Controller
                    render={({ field }) => (
                        <ToggleButtonGroup
                            color="primary"
                            {...field}
                            options={weekdayOpts}
                            size="small"
                            label={t('Modules.Main.Campaigns.CampaignSchedule.Form.weekdaysHeader')}
                        />
                    )}
                    name={`rules.${index}.days_of_week`}
                />
            </Stack>
            <Controller
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('Modules.Main.Campaigns.CampaignSchedule.Form.sovHeader')}
                        type="number"
                        InputProps={{ inputMode: 'decimal' }}
                        onChange={e => handleSovChange(e, field)}
                    />
                )}
                name={`rules.${index}.sov`}
            />
            <Controller
                name={`rules.${index}.slot_length`}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={t('Modules.Main.Campaigns.CampaignSchedule.Form.slotLengthHeader')}
                        type="number"
                        inputProps={{ step: 'any', min: 0 }}
                        error={field.value < 0}
                        onChange={e => {
                            const value = parseFloat(e.target.value);
                            // eslint-disable-next-line no-restricted-globals
                            field.onChange(isNaN(value) ? '' : value);
                        }}
                        helperText={
                            field.value < 0
                                ? t(
                                      'Modules.Main.Campaigns.CampaignSchedule.Form.slotLengthHelperText',
                                  )
                                : ''
                        }
                    />
                )}
            />
        </Stack>
    );
};

const RuleHours: React.FC<{ index: number }> = ({ index }) => {
    const { append, fields, remove } = useFieldArray<ScheduleFormData>({
        name: `rules.${index}.hours`,
    });
    const addHour = useCallback(() => {
        append({
            from: null,
            to: null,
        } as ScheduleFormDataRuleHour);
    }, [append]);
    return (
        <>
            <Typography variant="subtitle2">
                <Translate path="Modules.Main.Campaigns.CampaignSchedule.Form.timeHeader" />
                <IconButton size="small" color="primary" onClick={addHour}>
                    <AddCircle />
                </IconButton>
            </Typography>
            {fields.map((field, fi) => (
                <RuleHour
                    key={field.id}
                    path={`rules.${index}.hours.${fi}`}
                    index={fi}
                    remove={remove}
                />
            ))}
        </>
    );
};

const RuleHour: React.FC<{ path: string; index: number; remove: (index: number) => void }> = ({
    path,
    index,
    remove,
}) => {
    const { watch } = useFormContext();
    const t = useCommonTranslation();

    const fromValue = watch(`${path}.from`);
    const toValue = watch(`${path}.to`);

    const onRemove = useCallback(() => {
        remove(index);
    }, [index, remove]);

    return (
        <Stack gap={2} direction="row" alignItems="center">
            <Controller
                name={`${path}.from`}
                render={({ field }) => (
                    <TimePickerFieldWith24Hours
                        {...field}
                        value={fromValue}
                        label={t('Modules.Main.Campaigns.CampaignSchedule.Form.startTimeLabel')}
                    />
                )}
            />
            <Controller
                name={`${path}.to`}
                render={({ field }) => (
                    <TimePickerFieldWith24Hours
                        {...field}
                        value={toValue}
                        label={t('Modules.Main.Campaigns.CampaignSchedule.Form.endTimeLabel')}
                        show24HoursOption
                    />
                )}
            />
            <IconButton onClick={onRemove} size="small">
                <Delete />
            </IconButton>
        </Stack>
    );
};

const RuleFormWrapper: React.FC<Props> = props => {
    const { onOpen, index, remove, isOpen } = props;
    const onOpenClicked = useCallback(() => {
        onOpen(!isOpen ? index : -1);
    }, [index, onOpen, isOpen]);

    const onRemove = useCallback(() => {
        remove(index);
    }, [index, remove]);

    const removeBtn = useMemo(
        () => (
            <IconButton onClick={onRemove} size="small">
                <Delete />
            </IconButton>
        ),
        [onRemove],
    );

    const { watch } = useFormContext();
    const ruleFormData = formRuleDataToRule(watch(`rules.${index}`));
    const memoisedRuleData = useDeepCompareMemo(() => ruleFormData, [ruleFormData]);

    const t = useCommonTranslation();
    const daysOfWeek = useMemo(
        () =>
            Object.keys(reject(equals(false))(memoisedRuleData.days_of_week))
                .map(dayName => t(`WeekdaysShort.${ScheduleDayToJSDayNumber[dayName]}`))
                .join(', '),
        [memoisedRuleData.days_of_week, t],
    );
    const parseHoursOfDay = hoursOfDay => {
        const intervals = Object.entries(hoursOfDay);
        const timeRanges = [];
        let start = null;

        intervals.forEach(([key, value], index) => {
            if (value && start === null) {
                start = key.split(' - ')[0];
            }
            const isLast = index === intervals.length - 1;
            if ((!value && start !== null) || (isLast && value)) {
                const end = key.split(' - ')[1];
                timeRanges.push({ start, end: isLast && value ? end : key.split(' - ')[0] });
                start = null;
            }
        });

        return timeRanges;
    };

    const timePairs = useMemo(() => {
        const ranges = parseHoursOfDay(memoisedRuleData.hours_of_day);
        return ranges.map(pair => `${pair.start} - ${pair.end}`).join(', ');
    }, [memoisedRuleData.hours_of_day]);

    const { formatDateString } = useDateUtils();
    const dateRange = useMemo(
        () =>
            reject(isNil, [memoisedRuleData.start_date, memoisedRuleData.end_date])
                .map(date => formatDateString({ date, removeTime: true }))
                .join(' -> '),
        [memoisedRuleData, formatDateString],
    );

    const headerTitle = useMemo(
        () => <>{dateRange.length > 0 ? dateRange : `#${index + 1}`}</>,
        [index, dateRange],
    );
    const headerSubTitle = useMemo(
        () => (
            <Stack gap={1}>
                {reject(isEmpty, [timePairs, daysOfWeek]).map(str => (
                    <Box key={str}>{str}</Box>
                ))}
                {memoisedRuleData.sov && (
                    <Box>
                        {t('Modules.Main.Campaigns.CampaignSchedule.Form.sovHeader')}:{' '}
                        {memoisedRuleData.sov}
                    </Box>
                )}
                {memoisedRuleData.slot_length && (
                    <Box>
                        {t('Modules.Main.Campaigns.CampaignSchedule.Form.slotLengthHeader')}:{' '}
                        {memoisedRuleData.slot_length}
                    </Box>
                )}
            </Stack>
        ),
        [timePairs, daysOfWeek, memoisedRuleData.sov, memoisedRuleData.slot_length, t],
    );
    const header = useMemo(
        () => (
            <ListItemButton onClick={onOpenClicked} selected={isOpen}>
                <ListItemText
                    primary={headerTitle}
                    secondary={headerSubTitle}
                    secondaryTypographyProps={{ variant: 'body2', component: Box as any }}
                />
                {removeBtn}
            </ListItemButton>
        ),
        [onOpenClicked, headerTitle, headerSubTitle, removeBtn, isOpen],
    );

    const form = useMemo(
        () => (
            <RuleForm
                index={index}
                dateRangeSlotProps={props.dateRangeSlotProps}
                hideDates={props.hideDates}
            />
        ),
        [index, props.dateRangeSlotProps, props.hideDates],
    );

    return (
        <Box>
            {header}
            <Collapse in={props.isOpen}>{form}</Collapse>
        </Box>
    );
};

export default RuleFormWrapper;
