import React, { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { FilterConfigRendererProps, FilterConfigSelect } from 'c-pagination';
import { AutocompleteProps, Filterdropdown } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import equal from 'fast-deep-equal';
import { sortByName } from 'c-main/Components/Campaign/CampaignReports/Views/ByField/ChartTypes/sorting';
import { Direction } from 'c-types';

type Props = FilterConfigRendererProps<FilterConfigSelect>;

const DropdownFilter: React.FC<Props> = ({ config, onChange, value, id, className }) => {
    const t = useCommonTranslation();

    /**
     * Some labels may be translation path strings. Attempt to translate them.
     */
    const translatedOptions = useMemo(() => {
        const translated = config.options.map(opt => ({
            label: t(opt.label),
            value: opt.value,
            disabled: opt.disabled ?? false,
        }));

        const enabledOptions = translated.filter(opt => !opt.disabled);
        const disabledOptions = translated.filter(opt => opt.disabled);

        // Sort each group alphabetically by label
        const sortedEnabledOptions = sortByName(enabledOptions, Direction.ASC, 'label', '', '');
        const sortedDisabledOptions = sortByName(disabledOptions, Direction.ASC, 'label', '', '');

        return [...sortedEnabledOptions, ...sortedDisabledOptions];
    }, [config.options, t]);

    const [localVal, setLocalVal] = useState(value ?? []);

    const isMulti = useMemo(
        () => (config.multiple != null ? config.multiple : true),
        [config.multiple],
    );
    const onValueChange: AutocompleteProps['onChange'] = useCallback(
        (vals, reason) => {
            if (vals == null || reason === 'clear') {
                setLocalVal([]);
                onChange([]);
            } else {
                setLocalVal(Array.isArray(vals) ? vals : [vals]);
            }
        },
        [onChange],
    );

    useEffect(() => {
        setLocalVal(curr => (equal(curr, value) ? curr : value));
    }, [value]);

    const onClose = useCallback(() => {
        if (!equal(localVal, value)) {
            onChange(localVal);
        }
    }, [localVal, onChange, value]);

    const dropdownValue = useMemo(() => {
        if (isMulti) {
            return localVal;
        }

        return localVal?.[0];
    }, [isMulti, localVal]);

    const dropdownList = useMemo(
        () => (
            <Filterdropdown
                id={id}
                className={`entity-list-filters ${className}`}
                options={translatedOptions}
                multiple={isMulti}
                value={dropdownValue}
                placeholder={t(config.label)}
                onChange={onValueChange}
                onClose={onClose}
            />
        ),
        [id, translatedOptions, isMulti, dropdownValue, t, config.label, onValueChange, onClose],
    );
    return <>{dropdownList}</>;
};

export default DropdownFilter;
