import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    Paper,
    Stack,
    TableContainer,
    TableFooter,
    Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
    AutoGrid,
    Button,
    SelectField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { ScheduleDayToJSDayNumber } from 'c-main/Components/Schedule/lib';

import TablePaginationNotEntity from 'c-pagination/Components/TablePagination/TablePaginationNotEntity';
import { formatDateToMonthYear } from 'c-lib';

const formatDate = date => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const times = [
    { value: '00', label: '00:00' },
    { value: '01', label: '01:00' },
    { value: '02', label: '02:00' },
    { value: '03', label: '03:00' },
    { value: '04', label: '04:00' },
    { value: '05', label: '05:00' },
    { value: '06', label: '06:00' },
    { value: '07', label: '07:00' },
    { value: '08', label: '08:00' },
    { value: '09', label: '09:00' },
    { value: '10', label: '10:00' },
    { value: '11', label: '11:00' },
    { value: '12', label: '12:00' },
    { value: '13', label: '13:00' },
    { value: '14', label: '14:00' },
    { value: '15', label: '15:00' },
    { value: '16', label: '16:00' },
    { value: '17', label: '17:00' },
    { value: '18', label: '18:00' },
    { value: '19', label: '19:00' },
    { value: '20', label: '20:00' },
    { value: '21', label: '21:00' },
    { value: '22', label: '22:00' },
    { value: '23', label: '23:00' },
];
function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
}
const days = Object.keys(ScheduleDayToJSDayNumber).map(key => ({
    label: capitalize(key),
    value: ScheduleDayToJSDayNumber[key] - 1,
}));

const Audience = ({ audienceVersions, frameId }) => {
    const t = useCommonTranslation();
    const [openRow, setOpenRow] = useState(null);
    const handleRowClick = id => {
        setOpenRow(openRow === id ? null : id);
    };
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t(
                                'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.versionId',
                            )}
                        </TableCell>
                        <TableCell>
                            {t(
                                'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.versionName',
                            )}
                        </TableCell>
                        <TableCell>
                            {t(
                                'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.releaseId',
                            )}
                        </TableCell>
                        <TableCell>
                            {t(
                                'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.fallback',
                            )}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {audienceVersions.map(row => (
                        <React.Fragment key={row.id}>
                            <TableRow
                                onClick={() => handleRowClick(row.id)}
                                sx={{
                                    borderBottom:
                                        openRow !== row.id
                                            ? '1px solid rgba(224, 224, 224, 1)'
                                            : 'none',
                                }}
                            >
                                <TableCell sx={{ border: 'none' }}>{row.id}</TableCell>
                                <TableCell sx={{ border: 'none' }}>{`${row.version_name} (${
                                    row?.source?.name ? row?.source?.name : 'N/A'
                                } - ${formatDateToMonthYear(row?.date_last_revision)})`}</TableCell>
                                <TableCell sx={{ border: 'none' }}>{row.release_id}</TableCell>
                                <TableCell sx={{ border: 'none' }}>
                                    {row.isFallback ? t('Yes') : t('No')}
                                </TableCell>
                                <TableCell sx={{ border: 'none' }}>
                                    <IconButton size="small">
                                        {openRow === row.id ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                        borderTop: 'none',
                                        borderBottom:
                                            openRow === row.id
                                                ? '1px solid rgba(224, 224, 224, 1)'
                                                : 'none',
                                    }}
                                    colSpan={5}
                                >
                                    <Collapse in={openRow === row.id} timeout="auto" unmountOnExit>
                                        {row.id === openRow && (
                                            <Stack spacing={2} margin={2}>
                                                <Typography variant="h2">
                                                    {row.version_name}
                                                </Typography>
                                                <CustomComponent
                                                    data={row}
                                                    openRow={openRow}
                                                    frameId={frameId}
                                                />
                                            </Stack>
                                        )}
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const formatDateToFirstOfMonth = date => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
};

const CustomComponent = ({ data, openRow, frameId }) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedDays, setSelectedDays] = useState<string[] | number[]>([]);
    const [selectedTimes, setSelectedTimes] = useState<number | null>(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const {
        start,
        data: audienceUploadData,
        isLoading,
        error: audienceUploadError,
    } = useAPIClientRequest(ApiClient.Entities.Displays_AudienceUpload.list);
    const {
        start: fallbackStart,
        data: fallbackData,
        isLoading: fallbackLoading,
        error: fallbackError,
    } = useAPIClientRequest(ApiClient.Entities.Displays_AudienceFallback.list);
    const handleDayChange = event => {
        setSelectedDays(event.target.value);
    };

    const handleTimeChange = event => {
        setSelectedTimes(event.target.value);
    };

    const handleDateChange = date => {
        setSelectedDate(formatDate(date));
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        handleCall(newPage, rowsPerPage);
    };

    const handleRowsPerPageChange = event => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(1);
        handleCall(1, newRowsPerPage);
    };

    const handleCall = useCallback(
        (currentPage = page, currentRowsPerPage = rowsPerPage) => {
            const from = [`>=${formatDateToFirstOfMonth(selectedDate)}`];
            const audience_version_id = data.id;
            const hour = selectedTimes ? `>=${selectedTimes}` : null;

            const filters = {
                'filter.audience_version_id': audience_version_id,
                'filter.f_day': selectedDays,
                'filter.f_hour': [hour],
                'filter.valid_from': from,
                'filter.frame_id': frameId,
            };

            if (data.isFallback) {
                fallbackStart({
                    filters,
                    page: currentPage,
                    perPage: currentRowsPerPage,
                });
            } else {
                start({
                    filters,
                    page: currentPage,
                    perPage: currentRowsPerPage,
                });
            }
        },
        [
            data.id,
            data.isFallback,
            fallbackStart,
            frameId,
            selectedDate,
            selectedDays,
            selectedTimes,
            start,
            page,
            rowsPerPage,
        ],
    );

    const t = useCommonTranslation();

    const JSDayNumberToScheduleDay = Object.fromEntries(
        Object.entries(ScheduleDayToJSDayNumber).map(([key, value]) => [
            value - 1,
            capitalize(key),
        ]),
    );

    return (
        <Stack>
            <AutoGrid direction="row" gap={2} xs={2} pb={2}>
                <Stack>
                    <Typography variant="body2">
                        {t('Modules.Displays.DisplaysDisplayEdit.audience.filters.dateFrom')}
                    </Typography>
                    <DatePicker
                        value={selectedDate ? new Date(selectedDate) : null}
                        onChange={handleDateChange}
                    />
                </Stack>
                <Stack>
                    <Typography variant="body2">
                        {t('Modules.Displays.DisplaysDisplayEdit.audience.filters.days')}
                    </Typography>
                    <SelectField
                        title="Modules.Displays.DisplaysDisplayEdit.audience.table.headers.fDay"
                        options={days}
                        value={selectedDays}
                        onChange={handleDayChange}
                        multiple
                        clearAll
                    />
                </Stack>
                <Stack>
                    <Typography variant="body2">
                        {t('Modules.Displays.DisplaysDisplayEdit.audience.filters.hours')}
                    </Typography>
                    <SelectField
                        title="Modules.Displays.DisplaysDisplayEdit.audience.table.headers.fHour"
                        options={times}
                        value={selectedTimes}
                        onChange={handleTimeChange}
                    />
                </Stack>
                <Box pt={4}>
                    <Button
                        variant="text"
                        onClick={() => handleCall(page, rowsPerPage)}
                        disabled={isLoading || fallbackLoading}
                    >
                        {t('Modules.Displays.DisplaysDisplayEdit.audience.filters.submit')}
                    </Button>
                </Box>
                {(isLoading || fallbackLoading) && (
                    <Box pt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </AutoGrid>
            {fallbackError && <Typography color="error">{String(fallbackError)}</Typography>}
            {audienceUploadError && (
                <Typography color="error">{String(audienceUploadError)}</Typography>
            )}
            {(audienceUploadData || fallbackData) && (
                <>
                    <TableContainer
                        component={Paper}
                        style={{ maxHeight: '60vh', overflow: 'auto' }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t(
                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.versionId',
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.validDateFrom',
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.fDay',
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.fHour',
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.audience',
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.sov',
                                        )}
                                    </TableCell>
                                    {data.isFallback > 0 && (
                                        <TableCell>
                                            {t(
                                                'Modules.Displays.DisplaysDisplayEdit.audience.table.headers.uploadedBy',
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(data.isFallback
                                    ? fallbackData
                                    : audienceUploadData
                                )?.data?.data?.map(item => {
                                    const day = JSDayNumberToScheduleDay[item.f_day];
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.audience_version_id}</TableCell>
                                            <TableCell>{item.valid_from}</TableCell>
                                            <TableCell>{day}</TableCell>
                                            <TableCell>{item.f_hour}</TableCell>
                                            <TableCell>{item.audience}</TableCell>
                                            <TableCell>{item.sov}</TableCell>
                                            {data.isFallback > 0 && (
                                                <TableCell>
                                                    {item.uploaded_by ??
                                                        t(
                                                            'Modules.Displays.DisplaysDisplayEdit.audience.table.body.NA',
                                                        )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TableFooter
                            style={{
                                position: 'sticky',
                                bottom: 0,
                                backgroundColor: 'white',
                                zIndex: 1,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <TableRow style={{ display: 'flex', width: '100%' }}>
                                <TableCell colSpan={5} style={{ flex: 1 }}>
                                    <TablePaginationNotEntity
                                        count={
                                            (data.isFallback ? fallbackData : audienceUploadData)
                                                ?.data?.meta?.pagination?.total || 0
                                        }
                                        totalPages={
                                            (data.isFallback ? fallbackData : audienceUploadData)
                                                ?.data?.meta?.pagination?.total_pages || 0
                                        }
                                        page={page}
                                        onPageChange={handlePageChange}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </TableContainer>
                </>
            )}
        </Stack>
    );
};

export default Audience;
