import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout, NetworkRequestState, timeout } from '@uniled/data-layer';
import { UserType } from '@uniled/api-sdk';
import { User } from 'c-entity-types';
import { AuthState } from './auth-types';

const initialState: AuthState = {
    loginState: { error: null, state: NetworkRequestState.Idle },
    validatingUserSession: { error: null, state: NetworkRequestState.Idle },
    sessionTimedOut: false,
    logoutState: { error: null, state: NetworkRequestState.Idle },
    revalidateState: { error: null, state: NetworkRequestState.Idle },
};

const slice = createSlice({
    initialState,
    name: 'auth',
    reducers: {
        resetLogin: state => ({
            ...state,
            loginState: { state: NetworkRequestState.Idle },
            authenticatedUser: null,
            availableLoginTypes: undefined,
        }),
        startLogin: state => ({
            ...state,
            loginState: { state: NetworkRequestState.InProgress },
            authenticatedUser: null,
        }),
        setAvailableLoginTypes: (state, action: PayloadAction<UserType[]>) => ({
            ...state,
            availableLoginTypes: action.payload,
            loginState: { state: NetworkRequestState.Idle },
        }),
        finishedLogin: (state, action: PayloadAction<{ user: User; isMobile: boolean }>) => ({
            ...state,
            loginState: { state: NetworkRequestState.Idle },
            authenticatedUser: action.payload.user,
            validatingUserSession: { state: NetworkRequestState.Success },
            availableLoginTypes: undefined,
        }),
        failedLogin: (state, action: PayloadAction<string>) => ({
            ...state,
            loginState: { state: NetworkRequestState.Error, error: action.payload },
            authenticatedUser: null,
        }),
        startLogout: state => ({
            ...state,
            logoutState: { state: NetworkRequestState.InProgress },
        }),
        finishedLogout: state => ({
            ...state,
            logoutState: { state: NetworkRequestState.Idle },
            authenticatedUser: null,
        }),
        failedLogout: (state, action: PayloadAction<string>) => ({
            ...state,
            logoutState: { state: NetworkRequestState.Idle, error: action.payload },
            authenticatedUser: null,
        }),
        startValidatingSession: state => ({
            ...state,
            validatingUserSession: { state: NetworkRequestState.InProgress },
        }),
        finishedValidatingSession: (state, action: PayloadAction<{ user: User }>) => ({
            ...state,
            validatingUserSession: { state: NetworkRequestState.Success },
            authenticatedUser: action.payload.user,
        }),
        failedValidatingSession: state => ({
            ...state,
            validatingUserSession: { state: NetworkRequestState.Error },
            authenticatedUser: null,
        }),
        startImpersonatingUser: state => ({
            ...state,
            startImpersonatingUser: { state: NetworkRequestState.InProgress },
        }),
        finishedImpersonatingUser: state => ({
            ...state,
            startImpersonatingUser: { state: NetworkRequestState.Success },
        }),
        failedImpersonatingUser: (state, action: PayloadAction<string>) => ({
            ...state,
            startImpersonatingUser: { state: NetworkRequestState.Error, error: action.payload },
        }),
        startStopImpersonatingUser: state => ({
            ...state,
            stopImpersonatingUser: { state: NetworkRequestState.InProgress },
        }),
        finishedStopImpersonatingUser: state => ({
            ...state,
            stopImpersonatingUser: { state: NetworkRequestState.Success },
        }),
        failedStopImpersonatingUser: (state, action: PayloadAction<string>) => ({
            ...state,
            stopImpersonatingUser: { state: NetworkRequestState.Error, error: action.payload },
        }),
        resetStartImpersonatingUser: state => ({
            ...state,
            startImpersonatingUser: { state: NetworkRequestState.Idle },
        }),
        resetStopImpersonatingUser: state => ({
            ...state,
            stopImpersonatingUser: { state: NetworkRequestState.Idle },
        }),
        startRevalidation: state => ({
            ...state,
            revalidateState: { state: NetworkRequestState.InProgress, error: null },
        }),
        finishedRevalidation: (state, action: PayloadAction<{ user: User; token: string }>) => ({
            ...state,
            revalidateState: { state: NetworkRequestState.Success, error: null },
            authenticatedUser: action.payload.user,
        }),
        failedRevalidation: (state, action: PayloadAction<string>) => ({
            ...state,
            revalidateState: { state: NetworkRequestState.Error, error: action.payload },
            authenticatedUser: null,
        }),
        resetRevalidationState: state => ({
            ...state,
            revalidateState: { state: NetworkRequestState.Idle, error: null },
        }),
    },
    extraReducers: builder => {
        builder.addCase(logout, () => initialState);
        builder.addCase(timeout, () => initialState);
        // builder.addCase(resetEntities, () => initialState);
    },
});

const authSlice = {
    authActions: slice.actions,
    authReducer: slice.reducer,
};

export default authSlice;
export const { authActions, authReducer } = authSlice;
