import React, { useMemo } from 'react';
import { Box, Button, Select, MenuItem, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

interface ScreenFooterProps {
    currentPage: number;
    totalPages: number;
    rowCount: number;
    perPage: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (size: number) => void;
    loading: boolean;
}

const ScreenFooter: React.FC<ScreenFooterProps> = ({
    currentPage,
    totalPages,
    rowCount,
    perPage,
    onPageChange,
    onPageSizeChange,
    loading,
}) => {
    const renderPageNumbers = useMemo(() => {
        const pageNumbers = [];
        const maxPagesToShow = 5;

        if (totalPages <= maxPagesToShow) {
            // eslint-disable-next-line no-plusplus
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <Button
                        key={i}
                        onClick={() => onPageChange(i)}
                        disabled={loading}
                        variant={i === currentPage ? 'contained' : 'text'}
                        size="small"
                    >
                        {i}
                    </Button>,
                );
            }
        } else {
            pageNumbers.push(
                <Button
                    key={1}
                    onClick={() => onPageChange(1)}
                    disabled={loading}
                    variant={currentPage === 1 ? 'contained' : 'text'}
                    size="small"
                >
                    1
                </Button>,
            );

            if (currentPage > 2 && currentPage < totalPages - 1) {
                if (currentPage > 3) {
                    pageNumbers.push(<span key="dots1">...</span>);
                }

                pageNumbers.push(
                    <Button
                        key={currentPage}
                        onClick={() => onPageChange(currentPage)}
                        disabled={loading}
                        variant="contained"
                        size="small"
                    >
                        {currentPage}
                    </Button>,
                );

                if (currentPage < totalPages - 2) {
                    pageNumbers.push(<span key="dots2">...</span>);
                }
            } else if (currentPage >= totalPages - 1) {
                pageNumbers.push(<span key="dots3">...</span>);
                pageNumbers.push(
                    <Button
                        key={totalPages - 1}
                        onClick={() => onPageChange(totalPages - 1)}
                        disabled={loading}
                        variant={currentPage === totalPages - 1 ? 'contained' : 'text'}
                        size="small"
                    >
                        {totalPages - 1}
                    </Button>,
                );
            } else {
                pageNumbers.push(
                    <Button
                        key={2}
                        onClick={() => onPageChange(2)}
                        disabled={loading}
                        variant={currentPage === 2 ? 'contained' : 'text'}
                        size="small"
                    >
                        2
                    </Button>,
                );
                pageNumbers.push(<span key="dots4">...</span>);
            }

            pageNumbers.push(
                <Button
                    key={totalPages}
                    onClick={() => onPageChange(totalPages)}
                    disabled={loading}
                    variant={totalPages === currentPage ? 'contained' : 'text'}
                    size="small"
                >
                    {totalPages}
                </Button>,
            );
        }

        return pageNumbers;
    }, [totalPages, currentPage, loading, onPageChange]);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
            sx={{ flexWrap: 'wrap' }}
        >
            <Box display="flex" alignItems="center" sx={{ flex: '1 1 auto', minWidth: '200px' }}>
                <Typography variant="body2" style={{ marginRight: 8 }}>
                    Rows per page:
                </Typography>
                <Select
                    value={perPage}
                    onChange={event => onPageSizeChange(Number(event.target.value))}
                    disabled={loading}
                    style={{ marginRight: 16 }}
                    size="small"
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                    <MenuItem value={5000}>5000</MenuItem>
                    <MenuItem value={10000}>10000</MenuItem>
                    <MenuItem value={100000}>100000</MenuItem>
                </Select>
                <Typography variant="body2">
                    {`Page ${currentPage} of ${totalPages} (${rowCount} rows)`}
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                sx={{ flex: '1 1 auto', minWidth: '200px', justifyContent: 'flex-end' }}
            >
                <IconButton
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1 || loading}
                    size="small"
                >
                    <ArrowBack />
                </IconButton>
                {renderPageNumbers}
                <IconButton
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || loading}
                    size="small"
                >
                    <ArrowForward />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ScreenFooter;
