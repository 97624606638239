import React, { useCallback, useMemo, useRef } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { AutoGrid, Button } from 'c-components';
import { Close, KeyboardArrowDown, Tv, Whatshot } from '@mui/icons-material';
import { Translate, useCommonTranslation } from 'c-translation';
import { PCAReportCountField, PCAReportMetric } from '@uniled/api-sdk';
import { MapDataType } from 'c-main/Components/Campaign/CampaignReports/types';
import { useBoolean } from 'react-hanger';

type Props = {
    dataTypes: MapDataType[];
    toggleMapType: (dataType: MapDataType) => void;
    metric: PCAReportMetric;
    countFields: PCAReportCountField[];
    heatLayerSelected: MapDataType;
};

const Controls: React.FC<Props> = ({
    dataTypes,
    toggleMapType: ToggleMap,
    countFields,
    metric,
    heatLayerSelected,
}) => {
    const btnRef = useRef();
    const { value: menuOpen, setTrue: toggleMenu, setFalse: closeMenu } = useBoolean(false);
    const markerSelected = dataTypes.indexOf(MapDataType.Marker) !== -1;

    const inScheduleSelected = countFields.indexOf(PCAReportCountField.InSchedule) !== -1;
    const outScheduleSelected = countFields.indexOf(PCAReportCountField.OutSchedule) !== -1;
    const unbookedSelected = countFields.indexOf(PCAReportCountField.UnBooked) !== -1;

    const t = useCommonTranslation();
    const buttonLabel = useMemo(() => {
        if (heatLayerSelected === MapDataType.HeatActual)
            return t(`Modules.Main.Campaigns.Overview.Reports.${metric}.mapSettingActualHeat`);
        if (heatLayerSelected === MapDataType.HeatExpected)
            return t(`Modules.Main.Campaigns.Overview.Reports.${metric}.mapSettingExpectedHeat`);
        if (heatLayerSelected === MapDataType.HeatPerformance)
            return t(`Modules.Main.Campaigns.Overview.Reports.map.heatmapPerformance`);
        if (heatLayerSelected === MapDataType.HeatInSchedule)
            return t('Modules.Main.Campaigns.Overview.Reports.map.heatmapInSchedule');
        if (heatLayerSelected === MapDataType.HeatOutSchedule)
            return t('Modules.Main.Campaigns.Overview.Reports.map.heatmapOutSchedule');
        if (heatLayerSelected === MapDataType.HeatUnbooked)
            return t('Modules.Main.Campaigns.Overview.Reports.map.heatmapUnbooked');

        return t('Modules.Main.Campaigns.Overview.Reports.map.noHeatmapSelected');
    }, [heatLayerSelected, t, metric]);

    const toggleMapType = useCallback(
        (type: MapDataType) => {
            closeMenu();
            ToggleMap(type);
        },
        [closeMenu, ToggleMap],
    );
    return (
        <>
            <AutoGrid spacing={1} className="mapButtons">
                <Button
                    ref={btnRef}
                    onClick={toggleMenu}
                    variant={
                        heatLayerSelected !== MapDataType.HeatNone && heatLayerSelected != null
                            ? 'contained'
                            : 'outlined'
                    }
                >
                    <Whatshot sx={{ mr: 0.5 }} />
                    {buttonLabel}
                    <KeyboardArrowDown />
                </Button>
                <Menu
                    anchorEl={btnRef.current}
                    open={menuOpen}
                    onClose={closeMenu}
                    sx={{ mt: 0.5 }}
                >
                    <MenuItem
                        onClick={() => toggleMapType(MapDataType.HeatNone)}
                        selected={
                            heatLayerSelected === MapDataType.HeatNone || heatLayerSelected == null
                        }
                    >
                        <Close />
                        <Translate path="Modules.Main.Campaigns.Overview.Reports.map.hideHeatmap" />
                    </MenuItem>
                    <MenuItem
                        onClick={() => toggleMapType(MapDataType.HeatActual)}
                        selected={heatLayerSelected === MapDataType.HeatActual}
                    >
                        <Whatshot />
                        <Translate
                            path={`Modules.Main.Campaigns.Overview.Reports.${metric}.mapSettingActualHeat`}
                        />
                    </MenuItem>
                    {inScheduleSelected && (
                        <MenuItem
                            onClick={() => toggleMapType(MapDataType.HeatExpected)}
                            selected={heatLayerSelected === MapDataType.HeatExpected}
                        >
                            <Whatshot />
                            <Translate
                                path={`Modules.Main.Campaigns.Overview.Reports.${metric}.mapSettingExpectedHeat`}
                            />
                        </MenuItem>
                    )}
                    {inScheduleSelected && (
                        <MenuItem
                            onClick={() => toggleMapType(MapDataType.HeatPerformance)}
                            selected={heatLayerSelected === MapDataType.HeatPerformance}
                        >
                            <Whatshot />
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.map.heatmapPerformance" />
                        </MenuItem>
                    )}
                    {inScheduleSelected && (
                        <MenuItem
                            onClick={() => toggleMapType(MapDataType.HeatInSchedule)}
                            selected={heatLayerSelected === MapDataType.HeatInSchedule}
                        >
                            <Whatshot />
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.map.heatmapInSchedule" />
                        </MenuItem>
                    )}
                    {outScheduleSelected && (
                        <MenuItem
                            onClick={() => toggleMapType(MapDataType.HeatOutSchedule)}
                            selected={heatLayerSelected === MapDataType.HeatOutSchedule}
                        >
                            <Whatshot />
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.map.heatmapOutSchedule" />
                        </MenuItem>
                    )}
                    {unbookedSelected && (
                        <MenuItem
                            onClick={() => toggleMapType(MapDataType.HeatUnbooked)}
                            selected={heatLayerSelected === MapDataType.HeatUnbooked}
                        >
                            <Whatshot />
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.map.heatmapUnbooked" />
                        </MenuItem>
                    )}
                </Menu>
                <Button
                    variant={markerSelected ? 'contained' : 'outlined'}
                    onClick={() => toggleMapType(MapDataType.Marker)}
                >
                    <Tv sx={{ mr: 0.5 }} />
                    <Translate path="Modules.Main.Campaigns.Overview.Reports.map.markersToggle" />
                </Button>
            </AutoGrid>
        </>
    );
};

export default React.memo(Controls);
