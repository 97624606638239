import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ReportConfig,
    ReportDateSettings,
    ReportDateTimeframe,
    ReportWrapperProps,
    ReportWrapperRefAPI,
} from 'c-reports/Types';
import {
    CampaignNoticeKey,
    CampaignStats,
    ElasticSearchGroupByFieldResponse,
    EsPCAReportField,
    PCAReportEnergyByFieldResponse,
    PCAReportField,
} from '@uniled/api-sdk';
import { apiClient } from 'c-data';
import { format, parse } from 'date-fns';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import { useUrlState } from 'c-hooks';
import { allFieldValuesEs } from 'c-main/Lib';
import {
    PCAMetricUrlStateKeys,
    PCAUrlState,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { usePrevious } from 'react-hanger';
import { ReportFieldFilterES } from 'c-main/Components/Campaign/CampaignReports/ReportFieldFilter';
import {
    PCAViewControlProps,
    PCAViewControls,
} from 'c-main/Components/Campaign/CampaignReports/PCAViewControls';
import { Box, Collapse, Divider } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { scrollToTopReportingTab } from 'c-main/Components/constants';
import { performanceStatsToOldCampaignStats } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';
import OverallLeaderboardCharts from './OverallLeaderboardCharts';
import MetricPerformance from './MetricPerformance';

type Props = {
    id: number;
};

const urlDateFormat = 'yyyy-MM-dd';

const EsEnergyUsage: React.FC<Props> = ({ id }) => {
    const [pcaData, setPcaData] = useState<ElasticSearchGroupByFieldResponse>(null);
    const {
        campaignStart,
        campaignEnd,

        urlDateValue,

        advancedFilters,
        showAdvancedFilters,

        view,
        field,
        fieldSearchValue,

        reportType,

        byFieldReportType,

        countFields,

        urlEsSelfRefiningFiltersValue,

        onSwitchField,
        onSwitchView,
        onSearchTermUpdated,
        onReportTypeSwitched,
        onByFieldReportTypeSwitched,
        onAdvancedFiltersUpdated,
        onResetAdvancedFilters,
        environmentMode,

        notices,
        dataStatusFilter,
        setDataStatusFilter,
    } = useCampaignWrapperState(id);

    const fetchData: ReportConfig<PCAReportEnergyByFieldResponse>['fetchData'] = useCallback(
        (start, end, timeframe) =>
            apiClient.ReportsElasticSearch.pcaGroupByField({
                campaignId: id,
                showStats: true,
                startDate:
                    timeframe === ReportDateTimeframe.All
                        ? undefined
                        : `${format(start, 'yyyy-MM-dd')} 00:00:00`,
                endDate:
                    timeframe === ReportDateTimeframe.All
                        ? undefined
                        : `${format(end, 'yyyy-MM-dd')} 23:59:59`,
                field: (field as EsPCAReportField) ?? EsPCAReportField.StartDate,
                environment: environmentMode,
                fieldFilters: advancedFilters,
                dataStatus: advancedFilters?.[PCAReportField.DataStatus] as CampaignNoticeKey[],
            }),
        // return apiClient.Reports.pcaReportEnergy({
        //     field: field as PCAReportField,
        //     campaignId: campaign.id,
        //     startDate:
        //         timeframe === ReportDateTimeframe.All ? undefined : format(start, 'yyyy-MM-dd'),
        //     endDate:
        //         timeframe === ReportDateTimeframe.All ? undefined : format(end, 'yyyy-MM-dd'),
        //     campaignStats: true,
        //     timeFrame: null,
        //     fieldFilters: advancedFilters,
        //     metric: null,
        //     environment,
        // });
        [field, advancedFilters, environmentMode, id],
    );

    const { push } = useUrlState();

    const reportWrapperRef = useRef<ReportWrapperRefAPI>();

    const allValuesByField = useMemo(
        () =>
            pcaData?.filters
                ? allFieldValuesEs(pcaData?.filters, urlEsSelfRefiningFiltersValue)
                : null,
        [pcaData, urlEsSelfRefiningFiltersValue],
    );

    const initialStartDate = useMemo(() => {
        if (urlDateValue.startDate == null) {
            return new Date(campaignStart);
        }
        return parse(urlDateValue.startDate, urlDateFormat, new Date());
    }, [urlDateValue.startDate, campaignStart]);

    const initialEndDate = useMemo(() => {
        if (urlDateValue.endDate == null) {
            return new Date(campaignEnd);
        }
        return parse(urlDateValue.endDate, urlDateFormat, new Date());
    }, [urlDateValue.endDate, campaignEnd]);

    const filtersCount = useMemo(
        () =>
            Object.entries(advancedFilters ?? {}).reduce((acc, [field, values]) => {
                if (values?.length > 0) {
                    return acc + 1;
                }

                return acc;
            }, 0),
        [advancedFilters],
    );

    const [campaignStats, setCampaignStats] = useState<CampaignStats>(null);

    const scrollDownOnce = useRef(false);
    const prevCampaignStats = usePrevious(campaignStats);
    useEffect(() => {
        if (prevCampaignStats == null && campaignStats != null && !scrollDownOnce.current) {
            scrollDownOnce.current = true;
            scrollToTopReportingTab();
        }
    }, [campaignStats, prevCampaignStats]);

    const dateSettings = useMemo<ReportDateSettings>(
        () => ({
            minDate: campaignStart,
            maxDate: campaignEnd,
            availableTimeframes: [
                ReportDateTimeframe.Daily,
                ReportDateTimeframe.Weekly,
                ReportDateTimeframe.Monthly,
                ReportDateTimeframe.Range,
            ],
            timeframe: urlDateValue.timeframe,
            onDatesUpdated: (timeframe, start, end) => {
                push(PCAMetricUrlStateKeys.date, {
                    timeframe,
                    startDate:
                        start && timeframe !== ReportDateTimeframe.All
                            ? format(start, urlDateFormat)
                            : undefined,
                    endDate:
                        end && timeframe !== ReportDateTimeframe.All
                            ? format(end, urlDateFormat)
                            : undefined,
                } as PCAUrlState['date']);
            },
        }),
        [campaignEnd, campaignStart, urlDateValue.timeframe, push],
    );

    const prevTimeframeVal = usePrevious(urlDateValue.timeframe);

    useEffect(() => {
        // set the current timeframe from the URL if it is not the same as the current state
        // the prev value check is just there so this doesn't get called unnecessarily on mount
        if (prevTimeframeVal != undefined && prevTimeframeVal !== urlDateValue.timeframe) {
            reportWrapperRef?.current?.setDates(
                urlDateValue.timeframe,
                initialStartDate,
                initialEndDate,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlDateValue]);

    const prevCountFields = usePrevious(countFields);
    const prevAdvancedFilters = usePrevious(advancedFilters);

    useEffect(() => {
        // just to stop it fetching data on mount
        if (prevCountFields != undefined && prevAdvancedFilters != undefined) {
            reportWrapperRef?.current?.refreshData?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countFields, advancedFilters]);

    const advancedFilter = useMemo(
        () =>
            allValuesByField ? (
                <ReportFieldFilterES
                    allValuesByField={allValuesByField}
                    selectedFields={advancedFilters}
                    onSearch={onAdvancedFiltersUpdated}
                    onReset={onResetAdvancedFilters}
                />
            ) : null,
        [advancedFilters, allValuesByField, onAdvancedFiltersUpdated, onResetAdvancedFilters],
    );

    const viewControlsProps = useMemo<PCAViewControlProps>(
        () => ({
            searchValue: fieldSearchValue,
            onSearchValueUpdated: onSearchTermUpdated,
            field,
            type: reportType,
            onTypeChange: onReportTypeSwitched,
            metric: null,
            onFieldSwitch: onSwitchField,
            view,
            onViewChange: onSwitchView,
            showAdvancedFilters,
            filtersCount,
            onDataStatusChange: setDataStatusFilter,
            dataStatus: dataStatusFilter,
            notices,
        }),
        [
            fieldSearchValue,
            filtersCount,
            onReportTypeSwitched,
            onSearchTermUpdated,
            onSwitchField,
            onSwitchView,
            reportType,
            showAdvancedFilters,
            view,
            field,
            notices,
            dataStatusFilter,
            setDataStatusFilter,
        ],
    );

    const onDataUpdated: ReportConfig<any>['onDataUpdated'] = useCallback(
        (data: ElasticSearchGroupByFieldResponse, start, end, timeframe) => {
            setPcaData(data);
            if (data) {
                setCampaignStats(performanceStatsToOldCampaignStats(data.campaign_stats));
            }
        },
        [],
    );
    const fieldLabel = useCommonTranslation(
        `Modules.Main.Campaigns.Overview.Reports.fields.${field}`,
    );

    const reportWrapperProps = useMemo<ReportWrapperProps<any>>(
        () => ({
            ref: reportWrapperRef,
            requestKeyPrefix: countFields.join(''),
            fetchData,
            onDataUpdated,
            initialStartDate,
            initialEndDate,
            dateSettings,
            reportChartWrapperProps: { height: '75vh' },
            reportWrapperProps: {
                sx: {
                    bgcolor: 'white',
                    mt: 1,
                    pb: 1,
                },
            },
            beforeChartComponent: (
                <>
                    {advancedFilter && (
                        <Collapse in={showAdvancedFilters.value}>{advancedFilter}</Collapse>
                    )}
                </>
            ),

            additionalDateControls: (
                <>
                    <PCAViewControls
                        {...viewControlsProps}
                        field={field}
                        type={byFieldReportType}
                        onTypeChange={onByFieldReportTypeSwitched}
                        removeMapView
                        removeOverallView
                        typeSwitcherDisabled
                    />
                </>
            ),
            additionalDateControlsPosition: 'end',
        }),
        [
            countFields,
            fetchData,
            onDataUpdated,
            initialStartDate,
            initialEndDate,
            dateSettings,
            advancedFilter,
            showAdvancedFilters.value,
            viewControlsProps,
            field,
            byFieldReportType,
            onByFieldReportTypeSwitched,
        ],
    );

    return (
        <Box id="energyUsage">
            {campaignStats && <MetricPerformance campaignStats={campaignStats} />}
            <Divider sx={{ my: 2, borderBottomWidth: 2, mb: 1 }} />
            <Box id="energyUsageTable">
                <OverallLeaderboardCharts
                    key={field}
                    campaignId={id}
                    data={pcaData?.data ?? {}}
                    fieldLabel={fieldLabel}
                    reportWrapperProps={reportWrapperProps}
                />
            </Box>
        </Box>
    );
};

export default EsEnergyUsage;
