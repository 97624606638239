import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Box } from '@mui/material';
import { SelectField } from 'c-components';
import { useCommonTranslation } from 'c-translation';

const TargetForm = ({ initialData, onSubmit, isUpdating, isLoading }) => {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            name: '',
            type: 'impacts',
            target: initialData?.target || '0.00000',
        },
    });

    useEffect(() => {
        if (initialData) {
            reset(initialData);
        }
    }, [initialData, reset]);

    const t = useCommonTranslation();

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            <Controller
                name="name"
                control={control}
                rules={{
                    required: t(
                        'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.name',
                    ),
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Name"
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                )}
            />
            <Controller
                name="type"
                control={control}
                rules={{
                    required: t(
                        'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.type',
                    ),
                }}
                render={({ field, fieldState: { error } }) => (
                    <SelectField
                        value={field.value}
                        options={[
                            { value: '', label: '' },
                            { value: 'impacts', label: 'Impacts' },
                            { value: 'plays', label: 'Plays' },
                        ]}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                )}
            />
            <Controller
                name="target"
                control={control}
                rules={{
                    required: t(
                        'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.target',
                    ),
                    validate: value => {
                        const parsed = parseFloat(value);
                        return (
                            (!Number.isNaN(parsed) && /^-?\d+(\.\d{1,5})?$/.test(value)) ||
                            t(
                                'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.targetErr',
                            )
                        );
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label="Target"
                        error={!!error}
                        helperText={error ? error.message : null}
                        onChange={e => {
                            const value = e.target.value;
                            field.onChange(value);
                        }}
                    />
                )}
            />
            <Button disabled={isLoading} type="submit" variant="contained" color="primary">
                {isUpdating
                    ? t('Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.updateTarget')
                    : t(
                          'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.createTarget',
                      )}
            </Button>
        </Box>
    );
};

export default TargetForm;
