import React, { useMemo } from 'react';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import {
    LoginPage,
    PasswordResetCodePage,
    RequestPasswordResetPage,
    VerifyEmailPage,
    InviteRegisterPage,
    CompleteRegistrationPage,
} from 'c-auth-module/Pages';
import { InviteRoutes, PreAuthRoutes } from 'c-routes';
import { useSelector } from 'react-redux';
import { authSelectors } from 'c-auth-module/Slices';
import { usePostAuthHome } from 'c-wrapper/Components/CommonWrapper';
import Revalidate from 'c-auth-module/Components/Revalidate/Revalidate';

const PreAuth = () => {
    const $user = useSelector(authSelectors.authenticatedUser);
    const postAuthHome = usePostAuthHome();

    const redirectInviteLink = useMemo(() => {
        const token = new URLSearchParams(window.location.search).get('token');
        return $user != null && token != null && token?.length > 0
            ? generatePath(InviteRoutes.InviteToken, { token })
            : null;
    }, [$user]);

    return (
        <Switch>
            {$user && (
                <Redirect to={redirectInviteLink != null ? redirectInviteLink : postAuthHome} />
            )}
            <Route path={PreAuthRoutes.Login}>
                <LoginPage />
            </Route>
            <Route path={PreAuthRoutes.Welcome}>
                <LoginPage welcomePage />
            </Route>
            <Route path={PreAuthRoutes.Revalidate}>
                <Revalidate />
            </Route>
            <Route path={PreAuthRoutes.RequestPasswordReset}>
                <RequestPasswordResetPage />
            </Route>
            <Route path={PreAuthRoutes.RequestPasswordResetCode}>
                <PasswordResetCodePage />
            </Route>
            <Route path={PreAuthRoutes.Register}>
                <InviteRegisterPage />
            </Route>
            <Route path={PreAuthRoutes.Verify}>
                <VerifyEmailPage />
            </Route>
            <Route path={PreAuthRoutes.CompleteRegistration}>
                <CompleteRegistrationPage />
            </Route>
            <Route>
                <Redirect to={PreAuthRoutes.Login} />
            </Route>
        </Switch>
    );
};

export default PreAuth;
