import React, { useState, useMemo, useRef } from 'react';
import {
    TextField,
    IconButton,
    Menu,
    MenuItem,
    Box,
    Button,
    Stack,
    InputAdornment,
} from '@mui/material';
import { AccessTime } from '@mui/icons-material';

type Props = {
    value: string | null;
    onChange: (value: string) => void;
    label?: string;
    show24HoursOption?: boolean;
    error?: boolean;
};

const generateHourOptions = (show24HoursOption: boolean) => {
    const hours = [];
    for (let i = 0; i < 24; i += 1) {
        const hour = i.toString().padStart(2, '0');
        hours.push(hour);
    }
    if (show24HoursOption) {
        hours.push('24');
    }
    return hours;
};

const generateMinuteOptions = () => ['00', '30'];

const TimePickerFieldWith24Hours = ({
    value,
    onChange,
    label,
    show24HoursOption = false,
    error,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [inputValue, setInputValue] = useState<string>(value || '00:00');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const hourOptions = useMemo(() => generateHourOptions(show24HoursOption), [show24HoursOption]);
    const minuteOptions = useMemo(() => generateMinuteOptions(), []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        // eslint-disable-next-line prefer-const
        let [hours, minutes] = inputValue.split(':');

        if (hours === '24' && minutes === '00') {
            hours = '00';
        } else if (hours === '24') {
            hours = '00';
        }

        const newValue = `${hours}:${minutes}`;
        setInputValue(newValue);
        onChange(newValue);
        setAnchorEl(null);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleBlur = () => {
        // eslint-disable-next-line prefer-const
        let [hours, minutes] = inputValue.split(':');

        if (hours === '24' && minutes === '00') {
            hours = '00';
        } else if (hours === '24') {
            hours = '00';
        }

        const validMinutes = minutes === '30' ? '30' : '00';

        const newValue = `${hours}:${validMinutes}`;
        setInputValue(newValue);
        onChange(newValue);
    };

    const handleConfirm = () => {
        // eslint-disable-next-line prefer-const
        let [hours, minutes] = inputValue.split(':');

        if (hours === '24' && minutes === '00') {
            hours = '00';
        } else if (hours === '24') {
            hours = '00';
        }

        const newValue = `${hours}:${minutes}`;
        setInputValue(newValue);
        onChange(newValue);
        handleClose();
    };

    const handleClear = () => {
        setInputValue('00:00');
        onChange('00:00');
        handleClose();
    };

    const handleHourClick = () => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(0, 2);
        }
    };

    const handleMinuteClick = () => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(3, 5);
        }
    };

    return (
        <Box>
            <TextField
                label={label}
                value={show24HoursOption && inputValue === '00:00' ? '24:00' : inputValue}
                fullWidth
                size="small"
                variant="standard"
                inputRef={inputRef}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{ marginRight: -1 }}>
                            <IconButton onClick={handleClick} edge="end" size="small">
                                <AccessTime />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onClick={e => {
                    const clickPosition = inputRef.current?.selectionStart;
                    if (clickPosition !== undefined && clickPosition <= 2) {
                        handleHourClick();
                    } else {
                        handleMinuteClick();
                    }
                }}
                error={error}
                helperText={error ? 'Invalid time' : ''}
            />

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        padding: '4px',
                        maxWidth: '8rem',
                    },
                }}
            >
                <Box sx={{ minWidth: '120px' }}>
                    <Stack direction="row" sx={{ borderBottom: '1px solid grey' }}>
                        <Box
                            sx={{
                                maxHeight: '9rem',
                                overflowY: 'auto',
                                borderRight: '1px solid grey',
                            }}
                        >
                            {hourOptions.map(hour => (
                                <MenuItem
                                    key={hour}
                                    selected={hour === inputValue.split(':')[0]}
                                    onClick={() =>
                                        setInputValue(`${hour}:${inputValue.split(':')[1]}`)
                                    }
                                    color="primary"
                                >
                                    {hour}
                                </MenuItem>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                maxHeight: '9rem',
                                overflowY: 'auto',
                            }}
                        >
                            {minuteOptions.map(minute => (
                                <MenuItem
                                    key={minute}
                                    selected={minute === inputValue.split(':')[1]}
                                    onClick={() =>
                                        setInputValue(`${inputValue.split(':')[0]}:${minute}`)
                                    }
                                >
                                    {minute}
                                </MenuItem>
                            ))}
                        </Box>
                    </Stack>

                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button
                            onClick={handleClear}
                            color="primary"
                            variant="text"
                            sx={{ fontSize: '0.75rem', padding: '4px 8px' }}
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            variant="text"
                            color="primary"
                            sx={{ fontSize: '0.75rem', padding: '4px 8px' }}
                        >
                            OK
                        </Button>
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
};

export default TimePickerFieldWith24Hours;
