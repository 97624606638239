import { format, isValid, parseISO } from 'date-fns';

export const getIsDateThisYear = (date: Date) => date.getFullYear() === new Date().getFullYear();

export const formatDateString = ({
    date,
    dateFormatString,
    removeTime = true,
    timeFormat: TimeFormatString,
}: {
    date: string | Date;
    dateFormatString?: string;
    removeTime?: boolean;
    timeFormat?: string;
}) => {
    if (date == null) return '';

    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    if (parsedDate == null || !isValid(parsedDate)) return '';

    const timeFormat = removeTime ? '' : ` ${TimeFormatString}`;

    return date ? format(parsedDate, `${dateFormatString}${timeFormat}`) : '';
};

export const formatDateStringIgnoreTime = ({
    date,
    dateFormatString,
}: {
    date: string | Date;
    dateFormatString?: string;
}) =>
    formatDateString({
        date: typeof date === 'string' ? isoDateNoTimeDiff(date) : date,
        dateFormatString,
    });

/**
 * Extract the date string portion of an ISO8601 datetime string without the time difference portion.
 * Basically ignore everything from the `T` and beyond.
 * @param dateString
 */
export const isoDateStringNoTimeDiff = (dateString: string) => {
    if (dateString == null) {
        return '';
    }

    const isoDate = parseISO(dateString);

    if (!isValid(isoDate)) {
        return '';
    }

    // expected ISO8601 date 2022-01-01T00:00:00+00:00
    const parts = dateString.split('T');

    return parts.length === 2 ? parts[0] : '';
};

/**
 * @see isoDateStringNoTimeDiff
 * @param dateString
 */
export const isoDateNoTimeDiff = (dateString: string) => {
    const noTimeString = isoDateStringNoTimeDiff(dateString);
    if (dateString == '') {
        return null;
    }

    return noTimeString === '' ? null : new Date(noTimeString);
};

export const formatDateToMonthYear = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
};
